import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


declare var $: any;

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
    // ANIMATION

    $(document).ready(function($) {
  
      // Function which adds the 'animated' class to any '.animatable' in view
      var doAnimations = function() {
        
        // Calc current offset and get all animatables
        var offset = $(window).scrollTop() + $(window).height(),
            $animatables = $('.animatable');
        
        // Unbind scroll handler if we have no animatables
        if ($animatables.length == 0) {
          $(window).off('scroll', doAnimations);
        }
        
        // Check all animatables and animate them if necessary
        $animatables.each(function(i) {
           var $animatable = $(this);
          if (($animatable.offset().top + $animatable.height() -1) < offset) {
            $animatable.removeClass('animatable').addClass('animated');
          }
        });
    
      };
      
      // Hook doAnimations on scroll, and trigger a scroll
      $(window).on('scroll', doAnimations);
      $(window).trigger('scroll');
    
    });

    //
  }


  moveEllora()
  {
    this.router.navigate(['/child-abstract',1])
  }

  moveSchool()
  {
    this.router.navigate(['/child-abstract',2])
  }

  moveLodge()
  {
    this.router.navigate(['/child-abstract',3])
  }

  moveInventory()
  {
    this.router.navigate(['/child-abstract',4])
  }

  moveAntibiotic()
  {
    this.router.navigate(['/child-abstract',5])
  }

  moveTicket()
  {
    this.router.navigate(['/child-abstract',6])
  }

  moveEngineering()
  {
    this.router.navigate(['/child-abstract',7])
  }

  moveStipend()
  {
    this.router.navigate(['/child-abstract',8])
  }
}
