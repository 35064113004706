<div class=" d-none d-sm-block">
  <!------------------------------------------TITLE----------------------------------------------->
 <div class="title-box uk-border-rounded p-1 d-none d-sm-block">
  <div class="bg-dark p-1 inner-title-box">
    <h1 class="title-text text-center font-weight-bold">
      custom software development
    </h1>
    <div class="text-right">
      <h5 class="text-light">
        <span class="sub-menu" routerLink="/home-page">Home </span>&nbsp;/&nbsp;
        <!-- <span class="sub-menu sub-menu-text font-weight-bold">Custom Software Development</span>&nbsp;/&nbsp; -->
        <span class="sub-menu" routerLink="/web-development"> Web Development</span>
      </h5>
    </div>
  </div>
 </div>
<!--------------------------------------------------TITLE END-------------------------------------------->
<div class="bg-light">
  <div class="container-fluid p-4">
    <h4 class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none">
      Custom software  development
    </h4>
    <div class="row">
      <div class="col-12">
        <h2 class="d-none d-sm-block">
          Custom Software Development if any of the following describe your business
        </h2> 
        <h5 class="font-weight-bold d-block d-sm-none">
          Custom Software Development if any of the following describe your business
        </h5> 
      </div>
      <div class="col-md-5 col-sm-12 col-12 w3-animate-left">
        <div class="card">
          <!-- <div class="top-sec">  -->
              <img src="./../../../assets/img/service/custom-sw-dev/img-01.jpg" class="img-fluid">
          <!-- </div> -->
          <div class="bottom-sec">
          </div>
        </div>
      </div>
      <div class="col-md-7 col-sm-12 col-12  w3-animate-right" style="margin-top: 25px;">
        <ol >
          <li>
            Your business uses an off-the-shelf software package, but it doesn't do everything you need.
          </li>
          <li>
            You have to modify your business practices to fit the software you're using.
          </li>
          <li>
            The software you use has features that you don't need that slow you down and make training difficult.
          </li>
          <li>
            Your business uses Manual/Labor intensive processes /Excel spreadsheets to track some or all of its data.
          </li>
          <li>
            Your business had to purchase several applications to accomplish tasks that would ideally all be done in one application.
          </li>
          <li>
            You or someone on your staff has developed an Access database to manage your data, but it doesn't meet all your needs or is
            unreliable.
          </li>
          <li>
            You have to hire a third party company to perform business processes you would
            do in-house if you had the right tools.
          </li>
          <li>
            You have to hire additional staff to perform business processes that could be automated.
          </li>
          <li>
            The software you're using doesn't allow you to produce all the reports you need.
          </li>
        </ol>
      </div>
    </div>
  </div>
    <!--------------------------------------------CSD END----------------------------------------------------->
  <!------------------------------------CARD FOR ROLE------------------------------------------------------>
  <div class="container-fluid p-4">
    <!-- <h2 class="text-body">
      Benefits of Custom Software app-custom-software-development
    </h2> -->
    <h2 class="d-none d-sm-block">
      Benefits of Custom Software app-custom-software-development
    </h2> 
    <h5 class="font-weight-bold d-block d-sm-none">
      Benefits of Custom Software app-custom-software-development
    </h5> 
      <div class="row"><br>
        <div class="col-md-6 col-sm-12 col-xs-12 mt-2 mb-3" >
          <div class="row mt-4">
            <div class="col-md-4 col-sm-12 col-xs-12 animatable bounceIn"><br />
              <img src="./../../../assets/img/service/custom-sw-dev/sol-1.png" style="width: 100%;" class="img-fluid"/>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12 animatable bounceIn"><br />
              <img src="./../../../assets/img/service/custom-sw-dev/sol-2.png" style="width: 100%;" class="img-fluid"/>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12 animatable bounceIn"><br />
              <img src="./../../../assets/img/service/custom-sw-dev/sol-3.png" style="width: 100%;" class="img-fluid"/>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 mt-4 animatable bounceInRight">
          <ul class="live" style="display: contents;" >
            <li class="li">
              Maximize employee efficiency
            </li>
            <li class="li">
              Streamline business processes
            </li>
            <li class="li">
              Reduce overhead
            </li> 
            <li class="li">
              Improve service to your customers
            </li> 
            <li class="li">
              Obtain business critical data
            </li>
            <li class="li">
              Improve data security
            </li>
            <li class="li">
              Gain a competitive edge over your competition
            </li>
            <li class="li">
              Fast turn-around times on the changes you need
            </li> 
            <li class="li">
              No ongoing license costs
            </li>
          </ul>
        </div>
    </div>
</div>
</div>
<!-------------------------------------BENEFIT OF CSD END----------------------------------------->
</div>







<!--------------------------------------- MOBILE VIEW ------------------------------------------------->

<div class=" d-block d-sm-none">
<div class="bg-light">
  <div class="container-fluid p-4">
    <h4 class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none">
      Custom software  development
    </h4>
    <div class="row">
      <div class="col-12">
        <h2 class="d-none d-sm-block">
          Custom Software Development if any of the following describe your business
        </h2> 
        <h5 class="font-weight-bold d-block d-sm-none">
          Custom Software Development if any of the following describe your business
        </h5> 
      </div>
      <div class="col-md-5 col-sm-12 col-12">
        <div class="card">
          <!-- <div class="top-sec">  -->
              <img src="./../../../assets/img/service/custom-sw-dev/img-01.jpg" class="img-fluid">
          <!-- </div> -->
          <div class="bottom-sec">
          </div>
        </div>
      </div>
      <div class="col-md-7 col-sm-12 col-12" style="margin-top: 25px;">
        <ol >
          <li>
            Your business uses an off-the-shelf software package, but it doesn't do everything you need.
          </li>
          <li>
            You have to modify your business practices to fit the software you're using.
          </li>
          <li>
            The software you use has features that you don't need that slow you down and make training difficult.
          </li>
          <li>
            Your business uses Manual/Labor intensive processes /Excel spreadsheets to track some or all of its data.
          </li>
          <li>
            Your business had to purchase several applications to accomplish tasks that would ideally all be done in one application.
          </li>
          <li>
            You or someone on your staff has developed an Access database to manage your data, but it doesn't meet all your needs or is
            unreliable.
          </li>
          <li>
            You have to hire a third party company to perform business processes you would
            do in-house if you had the right tools.
          </li>
          <li>
            You have to hire additional staff to perform business processes that could be automated.
          </li>
          <li>
            The software you're using doesn't allow you to produce all the reports you need.
          </li>
        </ol>
      </div>
    </div>
  </div>
    <!--------------------------------------------CSD END----------------------------------------------------->
  <!------------------------------------CARD FOR ROLE------------------------------------------------------>
  <div class="container-fluid p-4">
    <!-- <h2 class="text-body">
      Benefits of Custom Software app-custom-software-development
    </h2> -->
    <h2 class="d-none d-sm-block">
      Benefits of Custom Software app-custom-software-development
    </h2> 
    <h5 class="font-weight-bold d-block d-sm-none">
      Benefits of Custom Software app-custom-software-development
    </h5> 
      <div class="row"><br>
        <div class="col-md-6 col-sm-12 col-xs-12 mt-2 mb-3" >
          <div class="row mt-4">
            <div class="col-md-4 col-sm-12 col-xs-12"><br />
              <img src="./../../../assets/img/service/custom-sw-dev/sol-1.png" style="width: 100%;" class="img-fluid"/>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12"><br />
              <img src="./../../../assets/img/service/custom-sw-dev/sol-2.png" style="width: 100%;" class="img-fluid"/>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12"><br />
              <img src="./../../../assets/img/service/custom-sw-dev/sol-3.png" style="width: 100%;" class="img-fluid"/>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 mt-4">
          <ul class="live" style="display: contents;" >
            <li class="li">
              Maximize employee efficiency
            </li>
            <li class="li">
              Streamline business processes
            </li>
            <li class="li">
              Reduce overhead
            </li> 
            <li class="li">
              Improve service to your customers
            </li> 
            <li class="li">
              Obtain business critical data
            </li>
            <li class="li">
              Improve data security
            </li>
            <li class="li">
              Gain a competitive edge over your competition
            </li>
            <li class="li">
              Fast turn-around times on the changes you need
            </li> 
            <li class="li">
              No ongoing license costs
            </li>
          </ul>
        </div>
    </div>
</div>
<!-------------------------------------BENEFIT OF CSD END----------------------------------------->
</div>
 