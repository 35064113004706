import { Component, OnInit } from '@angular/core';

declare var bootbox: any;
declare var $: any;

@Component({
  selector: 'app-home-navbar',
  templateUrl: './home-navbar.component.html',
  styleUrls: ['./home-navbar.component.scss']
})
export class HomeNavbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    $('.navbar-nav .nav-link').click(function(){
      $('.navbar-nav .nav-link').removeClass('active');
      $(this).addClass('active');
  })

    $('.navbar-nav>li>a').on('click', function(){
      $('.navbar-collapse').collapse('hide');
  });

  $('.navbar-nav>li>div>a').on('click', function(){
    $('.navbar-collapse').collapse('hide');
});

    $(window).scroll(function(event) {
      function footer()
        {
            var scroll = $(window).scrollTop(); 
            if(scroll > 50)
            { 
                $(".brandname").fadeIn("slow").addClass("show");
            }
            else
            {
                $(".brandname").fadeOut("slow").removeClass("show");
            }
            
            /* clearTimeout($.data(this, 'scrollTimer'));
            $.data(this, 'scrollTimer', setTimeout(function() {
                if ($('.brandname').is(':hover')) {
                footer();
            }
                else
                {
                  $(".brandname").fadeOut("slow");
                }
        }, 500)); */
        }
        footer();
    });

  }

}
