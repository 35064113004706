<div class=" d-none d-sm-block">
  <!------------------------------------------TITLE----------------------------------------------->
 <div class="title-box uk-border-rounded p-1 d-none d-sm-block">
  <div class="bg-dark p-1 inner-title-box">
    <h1 class="title-text text-center font-weight-bold">
      Application Maintenance and Support
    </h1>
    <div class="text-right">
      <h5 class="text-light">
        <span class="sub-menu" routerLink="/home-page">Home </span>&nbsp;/&nbsp;
        <!-- <span class="sub-menu sub-menu-text font-weight-bold">Custom Software Development</span>&nbsp;/&nbsp; -->
        <span class="sub-menu" routerLink="/verticals"> Verticles</span>
      </h5>
    </div>
  </div>
 </div>
<!--------------------------------------------------TITLE END-------------------------------------------->
<div class="bg-light">
  <!-------------------------------------------CARD IMAGE---------------------------------------------------->
  <div class="container-fluid"><br>
      <h4 class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none">
          Maintenance
      </h4>
      <div class="row mb-4">
          <div  class="col-md-5 col-sm-12 col-xs-12 mt-2  animatable bounceIn" >
              <div class="card">
                  <!-- <div class="top-sec"> -->
                  <img src="./../../../assets/img/service/maintenance/img-01.jpg" style="width:100%;">
                  <!-- </div> -->
              </div>
          </div>
  <!------------------------------------------CARD IMAGE END--------------------------------------------------->

  <!----------------------------------------MAINTENANCE------------------------------------------------------->
          <div  class="col-md-7 col-sm-12 col-xs-12 animatable bounceInRight">
            <h1>Maintenance</h1>
              <p class="justify-content-center" style="text-indent: 10%;">
              <b><u></u></b>Annaa Silicon maintenance and support teams are competent
                  and have qualified engineers concentrating solely on maintenance and support activities.
                  Whether it is a software installation, daily service or product support or system
                  upgrades and improvements, our team will be an efficient 
                  resource to help you maximize return on investment and improve your business.</p>
          </div>
      </div>
  </div>
  <!-----------------------------------------MAINTENANCE END-------------------------------------------->
</div>
</div>




<!--------------------------------------- MOBILE VIEW ------------------------------------------------->

<div class=" d-block d-sm-none">
<div class="bg-light">
  <!-------------------------------------------CARD IMAGE---------------------------------------------------->
  <div class="container-fluid mt-2"><br>
      <h4 class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none">
          Maintenance
      </h4>
      <div class="row">
          <div  class="col-md-5 col-sm-12 col-xs-12 mt-2" >
              <div class="card">
                  <!-- <div class="top-sec"> -->
                  <img src="./../../../assets/img/service/maintenance/img-01.jpg" style="width:100%;">
                  <!-- </div> -->
              </div>
          </div>
  <!------------------------------------------CARD IMAGE END--------------------------------------------------->

  <!----------------------------------------MAINTENANCE------------------------------------------------------->
          <div  class="col-md-7 col-sm-12 col-xs-12"  ><br><br><br>
              <p class="justify-content-center" style="text-indent: 10%;">
              <b><u></u></b>Annaa Silicon maintenance and support teams are competent
                  and have qualified engineers concentrating solely on maintenance and support activities.
                  Whether it is a software installation, daily service or product support or system
                  upgrades and improvements, our team will be an efficient 
                  resource to help you maximize return on investment and improve your business.</p>
          </div>
      </div>
  </div>
  <!-----------------------------------------MAINTENANCE END-------------------------------------------->
  <div class="pb-5"></div>
</div>
 