<nav class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top" >
  <a class="navbar-brand text-center" href="#" id="brand-name">
      &nbsp;<img src="./../../assets/img/brand.png" />
        <span class="brandname" id="brand-name">
            &nbsp;<img src="./../../assets/img/ast-logo.png" class="brand-img">
        </span>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
  </button>
  <div id="navbarNavDropdown" class="navbar-collapse collapse">
      <ul class="navbar-nav mr-auto">
          
      </ul>
      <ul class="navbar-nav">
      <li class="nav-item">  
        <a routerLink="/home-page" class="nav-link text-light active" routerLinkActive="active">  
         Home
        </a>  
      </li>  
      <li class="nav-item">  
        <a routerLink="/about-us" class="nav-link text-light" routerLinkActive="active">  
         About Us 
        </a>  
      </li> 
      <li class="nav-item dropdown active">
          <div class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown">
            Services
          </div>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" routerLink="/custom-software-development">Custom Software Development</a>
              <a class="dropdown-item" routerLink="/web-development">Web Development</a>
              <a class="dropdown-item" routerLink="/mobile-development">Mobile Application Development</a>
              <a class="dropdown-item" routerLink="/web-hosting">Web Hosting, SEO Services</a>
              <a class="dropdown-item" routerLink="/maintenance">Application Maintenance & Support</a>
          </div>
      </li>
      <li class="nav-item">  
          <a routerLink="/verticals" class="nav-link text-light" routerLinkActive="active">  
              Verticals
          </a>  
      </li>  
      <li class="nav-item">  
          <a routerLink="/ourproducts" class="nav-link text-light" routerLinkActive="active">  
              Our Products
          </a>  
      </li> 
        <li class="nav-item">  
          <a routerLink="/clients" class="nav-link text-light" routerLinkActive="active">  
              Clients
          </a>  
        </li>  
        <li class="nav-item">  
          <a routerLink="/testimonials" class="nav-link text-light" routerLinkActive="active">  
              Testimonials
          </a>  
        </li>    
        <!-- <li class="nav-item dropdown active">
          <div class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown">
            careers
          </div>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" routerLink="/upload-cv">Upload Your CV</a>
          </div>
      </li> -->
      <li class="nav-item">  
        <a routerLink="/upload-cv" class="nav-link text-light" routerLinkActive="active">  
           Careers
        </a>  
    </li>
      <li class="nav-item">  
          <a routerLink="/contact-us" class="nav-link text-light" routerLinkActive="active">  
             Contact Us
          </a>  
      </li>
      <!-- <li class="nav-item">
        <a routerLink="/login" class="nav-link text-light" routerLinkActive="active">
          Log-In
        </a>
      </li> -->
    </ul>  
  </div>  
</nav>  
  <!-- <router-outlet></router-outlet>   -->
