import { Component, OnInit } from '@angular/core';


declare var $: any;

@Component({
  selector: 'app-mobile-development',
  templateUrl: './mobile-development.component.html',
  styleUrls: ['./mobile-development.component.scss']
})
export class MobileDevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    // ANIMATION

    $(document).ready(function($) {
  
      // Function which adds the 'animated' class to any '.animatable' in view
      var doAnimations = function() {
        
        // Calc current offset and get all animatables
        var offset = $(window).scrollTop() + $(window).height(),
            $animatables = $('.animatable');
        
        // Unbind scroll handler if we have no animatables
        if ($animatables.length == 0) {
          $(window).off('scroll', doAnimations);
        }
        
        // Check all animatables and animate them if necessary
        $animatables.each(function(i) {
           var $animatable = $(this);
          if (($animatable.offset().top + $animatable.height() -1) < offset) {
            $animatable.removeClass('animatable').addClass('animated');
          }
        });
    
      };
      
      // Hook doAnimations on scroll, and trigger a scroll
      $(window).on('scroll', doAnimations);
      $(window).trigger('scroll');
    
    });

    //

    var mybutton = document.getElementById("myBtn");
  window.onscroll = function() {scrollFunction()};
  
  function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }
  
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}
  }

}
