import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-abstract',
  templateUrl: './abstract.component.html',
  styleUrls: ['./abstract.component.scss']
})
export class AbstractComponent implements OnInit {
id;
elloraFlag:boolean=false;
schoolFlag:boolean=false;
lodgeFlag:boolean=false;
inventoryFlag:boolean=false;
antibioticFlag:boolean=false;
ticketFlag:boolean=false;
engineeringFlag:boolean=false;
stipendFlag:boolean=false;
constructor(private route:ActivatedRoute,private router:Router) { }

  ngOnInit(): void
   {
    this.route.params.subscribe(parameters =>(this.id = parameters['data']));
    if(this.id==1)
    {
      this.elloraFlag=true;
    }
    else if(this.id==2)
    {
      this.schoolFlag=true;
    }
    else if(this.id==3)
    {
      this.lodgeFlag=true;
    }
    else if(this.id==4)
    {
      this.inventoryFlag=true;
    }
    else if(this.id==5)
    {
      this.antibioticFlag=true;
    }
    else if(this.id==6)
    {
      this.ticketFlag=true;
    }
    else if(this.id==7)
    {
      this.engineeringFlag=true;
    }
    else if(this.id==8)
    {
      this.stipendFlag=true;
    }
   
  }
  prev(){
    document.getElementById('slider-container').scrollLeft -= 270;
  }
  
  next()
  {
    document.getElementById('slider-container').scrollLeft += 270;
  }
  
  moveBack(){
    this.router.navigate([`\clients`]);
  }
}
