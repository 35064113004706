import * as moment from 'moment';
import { contactuspojo } from './../pojo/contactusPojo';

import { ContactUsServiceService } from './contact-us-service.service';
import { Component, OnInit } from '@angular/core';


declare var $: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactus = new contactuspojo();
  
  constructor(private ContactUsServiceService: ContactUsServiceService) { }

  ngOnInit() {

    // ANIMATION

    $(document).ready(function($) {
  
      // Function which adds the 'animated' class to any '.animatable' in view
      var doAnimations = function() {
        
        // Calc current offset and get all animatables
        var offset = $(window).scrollTop() + $(window).height(),
            $animatables = $('.animatable');
        
        // Unbind scroll handler if we have no animatables
        if ($animatables.length == 0) {
          $(window).off('scroll', doAnimations);
        }
        
        // Check all animatables and animate them if necessary
        $animatables.each(function(i) {
           var $animatable = $(this);
          if (($animatable.offset().top + $animatable.height() -1) < offset) {
            $animatable.removeClass('animatable').addClass('animated');
          }
        });
    
      };
      
      // Hook doAnimations on scroll, and trigger a scroll
      $(window).on('scroll', doAnimations);
      $(window).trigger('scroll');
    
    });

    //
    
    $(function() {
  $( "#button" ).click(function() {
    $( "#button" ).addClass( "onclick", 250, validate);
  });

  function validate() {
    setTimeout(function() {
      $( "#button" ).removeClass( "onclick" );
      $( "#button" ).addClass( "validate", 450, callback );
    }, 2250 );
  }
    function callback() {
      setTimeout(function() {
        $( "#button" ).removeClass( "validate" );
      }, 3000 );
    }
  });

  }

  submit1() {
    this.contactus.date = moment().format('YYYY-MM-DD').slice(0, 10);
    this.ContactUsServiceService.savecontactdetails(this.contactus).subscribe(res => {
      console.log(this.contactus);
      this.contactus = res;
      this.reset();
      alert('Successfully Submited Our Team With Be Get into Touch Shortly')
    });
  }

  reset(){
    this.contactus.id='';
    this.contactus.date='';
    this.contactus.name='';
    this.contactus.enquiryType='';
     this.contactus.mailId='';
     this.contactus.message='';
     this.contactus.mobileNumber='';
  }

  // submit2() {
  //   alert('Service Has Been Temporarily Stoped');
  // }

}
