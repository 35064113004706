
export class uploadcvpojo{
    id: number | any;
    fullName: string ;
    mobileNo: number ;
    mailId: string ;
    role: string ;
    skills:string;
    experience: string ;
    files: string;
    date:string;
}