import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { rolepojo } from 'src/app/pojo/rolepojo';
import { uploadcvpojo } from 'src/app/pojo/upload-cvpojo';
import { uploadPojo } from 'src/app/pojo/uploadpojo';
import { staticport } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class UploadCvService {

  constructor( private HttpClient: HttpClient) { }
  

  saveuploadcvdetails(arg:uploadcvpojo):Observable<uploadcvpojo>{
    const url = staticport.porturl + 'AddCareers';
    return this.HttpClient.post<uploadcvpojo>(url, arg);
   }
  
   uploadImage(file: File): Observable<uploadPojo>{
    // tslint:disable-next-line:prefer-const
           let formData: any = new FormData();
           // tslint:disable-next-line:prefer-const
                formData.append('image', file);
           const url = staticport.porturl + 'image/fileSystem';
           console.log(url)
           return this.HttpClient.post<uploadPojo>(url , formData);
}

getRole():Observable<rolepojo[]>{
  const url=staticport.porturl+'getAllRole';
  return this.HttpClient.get<rolepojo[]>(url);
}
}
