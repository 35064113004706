
    <!------------------------------------------TITLE----------------------------------------------->
 <div class="title-box uk-border-rounded p-1">
    <div class="bg-dark p-1 inner-title-box">
      <h1 class="title-text text-center font-weight-bold">
        upload your cv
      </h1>
      <div class="text-right">
        <h5 class="text-light">
          <span class="sub-menu" routerLink="/home-page">Home </span>&nbsp;/&nbsp;
          <!-- <span class="sub-menu sub-menu-text font-weight-bold">Custom Software Development</span>&nbsp;/&nbsp; -->
          <span class="sub-menu" routerLink="/contact-us"> Contact Us</span>
        </h5>
      </div>
    </div>
   </div>
  <!--------------------------------------------------TITLE END-------------------------------------------->


  
<div class="container-fluid mt-3 mb-3">
    <h4 class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none">
        
      </h4>
    <div class="row" style="box-shadow: 10px;">
        <div class="col-md-3 col-sm-12 col-xs-12 mb-2 w3-animate-left">
            <div class="container">
                <div class="card">
            <div class="bg-dark text-white card-header bgc ">
                We're Hiring
            </div>
            <div class="card-body">
                <div>
                    <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
                    Full Stack Developer
                </div>
                <div>
                    <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
                    Software Marketing Executive
                </div>
                <div>
                    <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
                    Support Engineer
                </div>
                <div >
                    <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
                    Admin
                </div>
            </div>
            <div class="bg-dark text-white card-footer bgc "></div>
              </div>
             </div>
             <br>
             <div class="container">
                <div class="card">
                    <div class="bg-dark text-white card-header bgc">
                         Internship
                    </div>
                    <div class="card-body">
                        <p>Internship for Students and Freshers</p>
                        <p style="color: blue;"><a href="./../../../assets/img/home/internship.jpg">Click Here</a></p>
                    </div>
                </div>

            </div>
                <br>
            <div class="container">
                <div class="card">  
                    <div class="bg-dark text-white card-header bgc ">HR Department Contact</div>
                    <div class="card-body">
                        <p>Send Your Resume
                     hr@annaasilicontechnology.com</p>
                        <p>Contact us<br>
                             0435 2427274</p>
                    </div>
                    <div class="bg-dark text-white  card-footer bgc">
                        
                    </div>
                </div>           
            </div>
            
        </div>
        <div class="col-md-9 col-sm-12 col-xs-12 w3-animate-right">
            <!-- <div class="spinner"></div> -->
            <div class="card">
                <!-- <div class="spinner"></div> -->
                <!-- <div class="p-2 border-top-0 border-left-0 border-right-0 border-light border text-light text-center font-weight-bolder">
                    <h3>Upload Your CV</h3>
                </div> -->
                <br>
                <div class="card-body text-dark text-justify">
                    <form (ngSubmit)="send()"  ngNativeValidate autocomplete="off">
                        <div class="form-row">
                          <div class="form-group col-md-6">
                                <label for="inputEmail4">Full Name *</label>
                              <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                      <span class="input-group-text" id="basic-addon1">
                                         <i class="fa fa-user icon" aria-hidden="true"></i>
                                      </span> 
                                  </div>
                                  <input type="text" class="form-control" id="inputEmail4" placeholder="Full Name" name="fullName" [(ngModel)]="uploadcv.fullName" required>
                                                             
                              </div>
                          </div>
                          <div class="form-group col-md-6">
                                 <label for="inputPassword4">Mobile Number *</label>
                               <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                       <span class="input-group-text" id="basic-addon1">
                                          <i class="fa fa-mobile icon" aria-hidden="true"></i>
                                        </span> 
                                  </div>
                                     <input type="tel" maxlength="10" class="form-control" id="inputPassword4" placeholder="Mobile No"  name="mobile" [(ngModel)]="uploadcv.mobileNo" required>
                               </div>
                          </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">
                              <label for="inputEmail4">Email *</label>
                              <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon1">
                                      <i class="fa fa-envelope  icon" aria-hidden="true"></i>
                                  </span> 
                                  </div>
                                  <input type="email" class="form-control" id="inputEmail4" placeholder="Email" name="email" [(ngModel)]="uploadcv.mailId" required>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputEmail4">Role *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-group icon" aria-hidden="true"></i>
                                    </span> 
                                    </div>
                                    <select id="inputState" class="form-control" name="role" [(ngModel)]="uploadcv.role" required>
                                        <option  value="null" selected>Select-Role</option>
                                        <option *ngFor="let item of role" value="{{item.id}}">{{item.rolename}}</option>
                                        
                                    </select>
                                    </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputPassword4">Total Experience *</label>
                                <div class="input-group mb-6">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-group icon" aria-hidden="true"></i>
                                    </span> 
                                    </div>
                                    <select id="inputState" class="form-control" name="experience" [(ngModel)]="uploadcv.experience" required>
                                        <option value ="null" selected>Total-Experience</option>
                                        <option value="Fresher">Fresher</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3 or above">3 and above</option>
                                    </select>
                                </div>
                            </div>


                            <div class="form-group col-md-6">
                                <label for="inputPassword4">Cv *</label>
                                <div class="input-group mb-6">
                                        <div class="input-group-prepend" style="width: 100%;">
                                            <!-- <input type="file" hidden id="uploadImage"> <button class="btn btn-sm upload" (click)="fileopen()" (change)="uploadAddFile($event)"><i class="fa fa-upload" aria-hidden="true"></i> Upload Image</button> -->
                                            <input type="file" class="form-control" name="images" accept="application/msword, application/pdf" [(ngModel)]="file"  (change)="uploadAddFile($event)" required>
                                        </div>
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="inputPassword4">Skills *</label>
                              <div class="input-group mb-3">
                                 <div class="input-group-prepend">
                                      <span class="input-group-text" id="basic-addon1">
                                         <i class="fa fa-pencil-square" aria-hidden="true"></i>
                                       </span> 
                                 </div>
                                    <input type="text" class="form-control" id="skills" placeholder="Skills"  name="skills" [(ngModel)]="uploadcv.skills" required>
                              </div>
                         </div>
                    
                          
                        </div>
                        <br>
                        <br>
                        <br>
                        
                

                        <div class="row text-center">
                            <div class="col-12 mb-2">
                                <button type="submit" class="btn btn-light pl-3 pr-3" [disabled]="btnstate">
                                    Send  &nbsp;
                                    <span class="icon">
                                        <i class="fa fa-paper-plane icon"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="col-12">
                                <button type="submit" class="btn btn-light" (click)="reset()">
                                    Reset&nbsp;
                                    <span class="icon">
                                        <i class="fa fa-refresh icon"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
    
<!--------------------------------------- MOBILE VIEW ------------------------------------------------->

<!-- <div class=" d-block d-sm-none">

<div class="container-fluid mt-3 mb-3">
    <h4 class="font-weight-bolder align-middle text-center text-uppercase">
        upload your cv
      </h4>
    <div class="row">
        <div class="col-md-3 col-sm-12 col-xs-12 mb-2">
            <div class="container">
                <div class="card">
                    <div class="bg-dark text-white card-header bgc ">HR Department Contact</div>
                    <div class="card-body">
                        <p>Send ur Resume
                        hr@annaasilicontechnology.com</p><br>
                        <p>Contact us by phone<br>
                             0435 2427274</p><br>
                    </div>
                    <div class="bg-dark text-white  card-footer bgc">
                        Upload Your CV
                    </div>
                </div> -->
                <!-- <div class="alert alert-danger">
                    <button type="button" class="close" data-dismiss="alert">&times;</button>
                    <strong>Notes: !</strong> Send Your CV(Resume) to hr@annasilicon.com & Contact us by phone +91 435 2427274.
                </div> -->
            <!-- </div>
        </div>
        <div class="col-md-9 col-sm-12 col-xs-12">
            <div class="card"> -->
                <!-- <div class="p-2 border-top-0 border-left-0 border-right-0 border-light border text-light text-center font-weight-bolder">
                    <h3>Upload Your CV</h3>
                </div> -->
                <!-- <div class="card-body text-dark text-justify">
                    <form>
                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <label for="inputEmail4">Full Name *</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon1">
                                    <i class="fa fa-user icon" aria-hidden="true"></i>
                                  </span> 
                                </div>
                                <input type="Name" class="form-control" id="inputEmail4" placeholder="Full Name" name="fullName" [(ngModel)]="uploadcv.fullName">
                              </div>
                          </div> -->
                          <!-- <div class="form-group col-md-6">
                                <label for="inputPassword4">Apply for the Post of *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-id-card icon" aria-hidden="true"></i>
                                    </span> 
                                    </div>
                                    <input type="text" class="form-control" id="inputPassword4" placeholder="Position">
                                </div>
                          </div> -->
                          <!-- <label for="inputEmail4">Email *</label>
                              <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon1">
                                      <i class="fa fa-envelope  icon" aria-hidden="true"></i>
                                  </span> 
                                  </div>
                                  <input type="email" class="form-control" id="inputEmail4" placeholder="Email"  name="email" [(ngModel)]="uploadcv.mailId">
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputPassword4">Mobile Number *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-mobile icon" aria-hidden="true"></i>
                                    </span> 
                                    </div>
                                    <input type="number" class="form-control" id="inputPassword4" placeholder="Mobile No" name="mobile" [(ngModel)]="uploadcv.mobileNo">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6"> -->
                                <!-- <div class="form-group col-md-6">
                                <label for="inputEmail4">Current Location *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-map-marker icon" aria-hidden="true"></i>
                                    </span> 
                                    </div>
                                    <input type="text" class="form-control" id="inputEmail4" placeholder="Location">
                                </div>
                            </div> -->
                            <!-- <div class="form-group col-md-6">
                                <label for="inputPassword4">Home Town *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-home icon" aria-hidden="true"></i>
                                    </span> 
                                    </div>
                                    <input type="text" class="form-control" id="inputPassword4" placeholder="Home Town">
                                </div>
                            </div> -->
                        <!-- </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputEmail4">Role *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-group icon" aria-hidden="true"></i>
                                    </span> 
                                    </div>
                                    <select id="inputState" class="form-control" name="role" [(ngModel)]="uploadcv.role">
                                    <option selected>Role</option>
                                      <option value="Backend Developer">Backend Developer</option>
                                      <option value="Frontend Developer">Frontend Developer</option>
                                      <option value="Support Engineer">Support Engineer</option>
                                      <option value="Admin">Admin</option>
                                      <option value="oftware Marketing">Software Marketing</option>
                                    </select>
                                    </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputPassword4">Total Experience *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-group icon" aria-hidden="true"></i>
                                    </span> 
                                    </div>
                                    <select id="inputState" class="form-control"  name="experience" [(ngModel)]="uploadcv.experience">
                                        <option selected>Total Experience</option>
                                        <option value="Fresher">Fresher</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3 Or above">3 Or above</option>
                                    </select>
                                </div>
                            </div>


                            <div class="modal-body">
                                <label for="addFile" class="font-weight-bold">Upload Files<sup>*</sup></label>
                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="Prepend"><i class="fa fa-file"></i></span>
                                    </div>
                                  <input type="file" class="form-control" name="images" multiple placeholder="upload" #images (change)="setFiles(images)" style="padding-bottom:30px;">
                                </div>
                                <div style="height: 0px">
                                    <table class="table table-striped" style="width: 100%;">
                                        <tbody>
                                            <tr *ngFor="let item of selectedFiles;index as i" class="tr-check">
                                                <td class="">{{i+1}}</td>
                                                <td class="">{{item.name}}</td>
                                                <td class="">{{item.size}}</td>
                                                <td class="">
                                                <i class="fa fa-times-circle-o text-primary" (click)="removeFile(i)" style="cursor: pointer;" ></i>
                                            </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div> -->
                            <!-- <div class="form-group col-md-4">
                                <label for="inputPassword4">Select CV *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                          <i class="fa fa-upload icon" aria-hidden="true"></i>
                                      </span>
                                    </div>
                                    <div class="custom-file">
                                      <input type="file" name="inputfile"  class="custom-file-input" id="inputGroupFile01"  name="file2" [(ngModel)]="uploadcv.files" (change)="setFile($event.target.files)">
                                      <label class="custom-file-label text-dark" for="inputGroupFile01">Choose file</label>
                                    </div>
                                  </div>
                            </div> -->
                        <!-- </div>
                        <div class="form-row"> -->
                            <!-- <div class="form-group col-md-6">
                              <label for="inputEmail4">Key Skills *</label>
                              <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon1">
                                    <i class="fa fa-key icon" aria-hidden="true"></i>
                                  </span> 
                                </div>
                                <input type="text" aria-rowspan="2" class="form-control" id="inputEmail4" placeholder="Key Skills">
                              </div>
                            </div> -->
                            <!-- <div class="form-group col-md-6">
                                <label for="inputPassword4">Write About You *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-pencil-square icon" aria-hidden="true"></i>
                                    </span> 
                                    </div>
                                    <input type="text" aria-rowspan="2" class="form-control" id="inputPassword4" placeholder="Write About You">
                                </div>
                            </div> -->
                        <!-- </div>
                        <div class="d-none d-sm-block">
                            <div class="row justify-content-md-center">
                                <div class="col-md-6 col-sm-12 col-12 text-right">
                                    <button id="submit" (click)="send()">
                                        Send  &nbsp;
                                        <span class="icon">
                                            <i class="fa fa-paper-plane icon"></i></span>
                                    </button>
                                </div>
                                <div class="col-md-6 col-sm-12 col-12 text-left">
                                    <button type="submit" class="btn btn-light"  >
                                        Reset&nbsp;
                                        <span class="icon">
                                            <i class="fa fa-refresh icon"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="row text-center d-block d-sm-none">
                            <div class="col-12 mb-2">
                                <button id="submit" (click)="send()">
                                    Send  &nbsp;
                                    <span class="icon">
                                        <i class="fa fa-paper-plane icon"></i></span>
                                </button>
                            </div>
                            <div class="col-12">
                                <button type="submit" class="btn btn-light">
                                    Reset&nbsp;
                                    <span class="icon">
                                        <i class="fa fa-refresh icon"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                      </form>
                </div>
            </div>
        </div>
    </div>
</div>
    
</div>
  -->