import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-our-products',
  templateUrl: './our-products.component.html',
  styleUrls: ['./our-products.component.scss']
})
export class OurProductsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    // ANIMATION

    $(document).ready(function ($) {

      // Function which adds the 'animated' class to any '.animatable' in view
      var doAnimations = function () {

        // Calc current offset and get all animatables
        var offset = $(window).scrollTop() + $(window).height(),
          $animatables = $('.animatable');

        // Unbind scroll handler if we have no animatables
        if ($animatables.length == 0) {
          $(window).off('scroll', doAnimations);
        }

        // Check all animatables and animate them if necessary
        $animatables.each(function (i) {
          var $animatable = $(this);
          if (($animatable.offset().top + $animatable.height() - 1) < offset) {
            $animatable.removeClass('animatable').addClass('animated');
          }
        });

      };

      // Hook doAnimations on scroll, and trigger a scroll
      $(window).on('scroll', doAnimations);
      $(window).trigger('scroll');

    });

    //
  }



  moveEllora() {
    this.router.navigate(['/child-productdetails', 1])
  }

  moveSchool() {
    this.router.navigate(['/child-productdetails', 2])
  }

  moveLodge() {
    this.router.navigate(['/child-productdetails', 3])
  }

  moveInventory() {
    this.router.navigate(['/child-productdetails', 4])
  }

  moveAntibiotic() {
    this.router.navigate(['/child-productdetails', 5])
  }

  moveTicket() {
    this.router.navigate(['/child-productdetails', 6])
  }

  moveEngineering() {
    this.router.navigate(['/child-productdetails', 7])
  }

  moveRetail() {
    this.router.navigate(['/child-productdetails', 8])
  }

  moveTemplesw() {
    this.router.navigate(['/child-productdetails', 9])
  }

}
