<div class=" d-none d-sm-block">
  <!------------------------------------------TITLE----------------------------------------------->
 <div class="title-box uk-border-rounded p-1 d-none d-sm-block">
  <div class="bg-dark p-1 inner-title-box">
    <h1 class="title-text text-center font-weight-bold">
      web development, designing and reengineerind
    </h1>
    <div class="text-right">
      <h5 class="text-light">
        <span class="sub-menu" routerLink="/home-page">Home </span>&nbsp;/&nbsp;
        <!-- <span class="sub-menu sub-menu-text font-weight-bold">Custom Software Development</span>&nbsp;/&nbsp; -->
        <span class="sub-menu" routerLink="/mobile-development">Mobile Application</span>
      </h5>
    </div>
  </div>
 </div>
<!--------------------------------------------------TITLE END-------------------------------------------->
<div class="bg-light">
  <!---------------------------------------------TITLE END------------------------------------------------------->
  <!--------------------------------------------WEB DEV PARA------------------------------------------------>
  <div class="container-fluid p-4">
    <h4 class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none">
      WEB DEVELOPMENT
    </h4>
    <div class="row">
      <div class="col-md-5 col-sm-12 col-xs-12 animatable bounceIn">
        <div class="card">
          <!-- <div class="top-sec"> -->
          <img src="./../../../assets/img/service/web-dev/img-01.jpg" style="width:100%;">
          <!-- </div> -->
        </div>
      </div>
      <div class="col-md-7 col-sm-12 col-xs-12 animatable bounceInRight">
        <p class=" justify-content-center" style="text-indent: 5%;margin-top: 50px;">
          We Craft the website keeping the credibility of your company to add more value to your
          business. Our web design enables world class browsing experience on Desktop, Laptop,
          IPAD and hand held Mobile devices. Responsive web design is focused around providing an
          intuitive and gratifying experience for everyone.
        </p>
        <p class=" justify-content-center" style="text-indent: 5%;">
          We always use the latest tools in technology and design to meet and exceed our client's goals.
          Web Designing is an art and we assure you that our creative team craft your web pages instead of just
          designing.
        </p>
        <p class=" justify-content-center" style="text-indent: 5%;">
          We always use the latest tools in technology and design to meet and exceed our client's goals.
          Web Designing is an art and we assure you that our creative team craft your web pages instead of just
          designing.
        </p>
        <p class=" justify-content-center" style="text-indent: 5%;">
          We leverage Agile methodology and work with requirements of our client and then set a
          customized strategy best suited for the clients.
        </p>
      </div>
    </div>
  </div>
  <!--------------------------------------------WEB DEV PARA END------------------------------------------------->
  <!-------------------------------------------------WEB APPLICATION--------------------------------------------->
  <div class="p-3">
    <div class="container-fluid my-4">
      <div class="row">
        <div class="col-md-12">
          <div class="main-timeline">
            <div class="timeline animatable bounceInLeft">
              <a href="#" class="timeline-content">
                <div class="timeline-icon">
                  <i class="fa fa-globe icon-1"></i>
                </div>
                <h3 class="title">INTRANET</h3>
                <p class="description">
                  An intranet allows employees to access a site where data can be securely collected and reported on. 
                  The intranet can be configured to be viewed from only a LAN or through the Internet. 
                  This portion of a Web Application provides tremendous business efficiency.
                </p>
              </a>
            </div>
            <div class="timeline animatable bounceInRight">
              <a href="#" class="timeline-content">
                <div class="timeline-icon">
                  <i class="fa fa-rocket icon-2"></i>
                </div>
                <h3 class="title">EXTRANET</h3>
                <p class="description">
                  An extranet displays restricted data for authorized customers or partners.
                  Intranet users have complete control over the content and user management for your extranet.
                </p>
              </a>
            </div>
            <div class="timeline animatable bounceInLeft">
              <a href="#" class="timeline-content">
                <div class="timeline-icon">
                  <i class="fa fa-user icon-3"></i>
                </div>
                <h3 class="title">WEBSITE</h3>
                <p class="description">
                  A website is completely public-facing.
                  It describes your business and entices potential customers or partners to join you.
                </p>
              </a>
            </div>
            <div class="timeline animatable bounceInRight">
              <a href="#" class="timeline-content">
                <div class="timeline-icon">
                  <i class="fa fa-briefcase icon-4"></i>
                </div>
                <h3 class="title">Benefits of Web Design ReEngineering</h3>
                <p class="description">
                  An intranet allows employees to access a site where data can be securely collected and reported on.
                  The intranet can be configured to be viewed from only a LAN or through the Internet.
                  This portion of a Web Application provides tremendous business efficiency.
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  <!-----------------------------------------WEB APPLICATION END------------------------------------------------->
</div>




<!--------------------------------------- MOBILE VIEW ------------------------------------------------->

<div class=" d-block d-sm-none">
 
<div class="bg-light">
  <!---------------------------------------------TITLE END------------------------------------------------------->
  <!--------------------------------------------WEB DEV PARA------------------------------------------------>
  <div class="container-fluid p-4">
    <h4 class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none">
      WEB DEVELOPMENT
    </h4>
    <div class="row">
      <div class="col-md-5 col-sm-12 col-xs-12">
        <div class="card">
          <!-- <div class="top-sec"> -->
          <img src="./../../../assets/img/service/web-dev/img-01.jpg" style="width:100%;">
          <!-- </div> -->
        </div>
      </div>
      <div class="col-md-7 col-sm-12 col-xs-12">
        <p class=" justify-content-center" style="text-indent: 5%;margin-top: 50px;">
          We Craft the website keeping the credibility of your company to add more value to your
          business. Our web design enables world class browsing experience on Desktop, Laptop,
          IPAD and hand held Mobile devices. Responsive web design is focused around providing an
          intuitive and gratifying experience for everyone.
        </p>
        <p class=" justify-content-center" style="text-indent: 5%;">
          We always use the latest tools in technology and design to meet and exceed our client's goals.
          Web Designing is an art and we assure you that our creative team craft your web pages instead of just
          designing.
        </p>
        <p class=" justify-content-center" style="text-indent: 5%;">
          We always use the latest tools in technology and design to meet and exceed our client's goals.
          Web Designing is an art and we assure you that our creative team craft your web pages instead of just
          designing.
        </p>
        <p class=" justify-content-center" style="text-indent: 5%;">
          We leverage Agile methodology and work with requirements of our client and then set a
          customized strategy best suited for the clients.
        </p>
      </div>
    </div>
  </div>
  <!--------------------------------------------WEB DEV PARA END------------------------------------------------->
  <!-------------------------------------------------WEB APPLICATION--------------------------------------------->
  <div class="p-3">
    <div class="container-fluid my-4">
      <div class="row">
        <div class="col-md-12">
          <div class="main-timeline">
            <div class="timeline">
              <a href="#" class="timeline-content">
                <div class="timeline-icon">
                  <i class="fa fa-globe icon-1"></i>
                </div>
                <h3 class="title">INTRANET</h3>
                <p class="description">
                  An intranet allows employees to access a site where data can be securely collected and reported on. 
                  The intranet can be configured to be viewed from only a LAN or through the Internet. 
                  This portion of a Web Application provides tremendous business efficiency.
                </p>
              </a>
            </div>
            <div class="timeline">
              <a href="#" class="timeline-content">
                <div class="timeline-icon">
                  <i class="fa fa-rocket icon-2"></i>
                </div>
                <h3 class="title">EXTRANET</h3>
                <p class="description">
                  An extranet displays restricted data for authorized customers or partners.
                  Intranet users have complete control over the content and user management for your extranet.
                </p>
              </a>
            </div>
            <div class="timeline">
              <a href="#" class="timeline-content">
                <div class="timeline-icon">
                  <i class="fa fa-user icon-3"></i>
                </div>
                <h3 class="title">WEBSITE</h3>
                <p class="description">
                  A website is completely public-facing.
                  It describes your business and entices potential customers or partners to join you.
                </p>
              </a>
            </div>
            <div class="timeline">
              <a href="#" class="timeline-content">
                <div class="timeline-icon">
                  <i class="fa fa-briefcase icon-4"></i>
                </div>
                <h3 class="title">Benefits of Web Design ReEngineering</h3>
                <p class="description">
                  An intranet allows employees to access a site where data can be securely collected and reported on.
                  The intranet can be configured to be viewed from only a LAN or through the Internet.
                  This portion of a Web Application provides tremendous business efficiency.
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-----------------------------------------WEB APPLICATION END------------------------------------------------->
</div>
 