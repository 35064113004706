import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


declare var $: any;


@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  id;
  elloraFlag: boolean = false;
  schoolFlag: boolean = false;
  lodgeFlag: boolean = false;
  inventoryFlag: boolean = false;
  antibioticFlag: boolean = false;
  ticketFlag: boolean = false;
  engineeringFlag: boolean = false;
  retailFlag: boolean = false;
  templeswFlag: boolean = false;
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {

    this.route.params.subscribe(parameters => (this.id = parameters['data']));
    if (this.id == 1) {
      this.elloraFlag = true;
    }
    else if (this.id == 2) {
      this.schoolFlag = true;
    }
    else if (this.id == 3) {
      this.lodgeFlag = true;
    }
    else if (this.id == 4) {
      this.inventoryFlag = true;
    }
    else if (this.id == 5) {
      this.antibioticFlag = true;
    }
    else if (this.id == 6) {
      this.ticketFlag = true;
    }
    else if (this.id == 7) {
      this.engineeringFlag = true;
    }
    else if (this.id == 8) {
      this.retailFlag = true;
    }
    else if (this.id == 9) {
      this.templeswFlag = true;
    }

    $(document).ready(function(){
      $(".accordion-title").click(function(e){
          var accordionitem = $(this).attr("data-tab");
          $("#"+accordionitem).slideToggle().parent().siblings().find(".accordion-content").slideUp();
  
          $(this).toggleClass("active-title");
          $("#"+accordionitem).parent().siblings().find(".accordion-title").removeClass("active-title");
  
          $("i.fa-chevron-down",this).toggleClass("chevron-top");
          $("#"+accordionitem).parent().siblings().find(".accordion-title i.fa-chevron-down").removeClass("chevron-top");
      });
      
  });

  }

  prev() {
    document.getElementById('slider-container').scrollLeft -= 270;
  }

  next() {
    document.getElementById('slider-container').scrollLeft += 270;
  }

  moveBack() {
    this.router.navigate([`\clients`]);
  }
}
