<div class=" d-none d-sm-block">
  <!------------------------------------------ HEADER IMAGE --------------------------------------->
 <!------------------------------------------TITLE----------------------------------------------->
 <div class="title-box uk-border-rounded p-1 d-none d-sm-block">
  <div class="bg-dark p-1 inner-title-box">
    <h1 class="title-text text-center font-weight-bold">
      clients
    </h1>
    <div class="text-right">
      <h5 class="text-light">
        <span class="sub-menu" routerLink="/home-page">Home </span>&nbsp;/&nbsp;
        <!-- <span class="sub-menu sub-menu-text font-weight-bold">Custom Software Development</span>&nbsp;/&nbsp; -->
        <span class="sub-menu" routerLink="/testimonials"> Testimonials</span>
      </h5>
    </div>
  </div>
 </div>
<!--------------------------------------------------TITLE END-------------------------------------------->
<!------------------------------------------ END ------------------------------------------------->

<!------------------------------------------ WEBSITES---------------------------------------------->
 <div class="bg-light">
  <!-- <div class="container" style="font-size: 40px; font-family: 'Times New Roman', Times, serif;">
    <div class="button-default button-slanted">
      <span class="button-slanted-content"></span>
    </div>
  </div>  -->
  <div class="container-fluid">
    <h4 class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none">
      clients
    </h4>

  <div class="row p-4  animatable bounceInLeft">
      <div class="col-12">
        <h1 class="text-center text-uppercase d-none d-sm-block">our Websites</h1>
        <h4 class="text-center text-uppercase d-block d-sm-none">our Websites</h4>
      </div>
  <!-------------------------------------Dhandayuthapani Temple-------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-19.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Dhandayuthapani Temple</h3>
            <ul class="icon">
                <li><a href="http://palanimurugantemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
        </div>
      </div> 
    </div> 

    <!------------------------------------Subramanya Swami-Thiruttani----------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-18.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Subramanya Swami-Thiruttani</h3>
            <ul class="icon">
                <li><a href="http://tiruttanigaimurugan.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div>

    <!-----------------------------------------Gowmariyamman Temple---------------------------------------->
        <div class="col-md-3 col-sm-6 col-xs-12 p-1">
          <div class="box12">
            <img src="./../../assets/img/clients/img-03.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title">Gowmariyamman Temple</h3>
                <ul class="icon">
                    <li><a href="http://gowmariyamman.org/" target="_blank"><i class="fa fa-link"></i></a></li>
                </ul>
              </div>
          </div> 
        </div>

    <!------------------------------------------Sri Vanchinathar Temple --------------------------------->
        <div class="col-md-3 col-sm-6 col-xs-12 p-1">
          <div class="box12">
            <img src="./../../assets/img/clients/img-04.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title"> Sri Vanchinathar Temple</h3>
                <ul class="icon">
                    <li><a href="http://srivaanjiyam.org/" target="_blank"><i class="fa fa-link"></i></a></li>
                </ul>
              </div>
          </div> 
        </div><br>
      </div>

    <div class="row p-4  animatable bounceInRight">
    
  <!--------------------------------------- Sarangapani Swamy Temple-------------------------------->
        <div class="col-md-3 col-sm-6 col-xs-12 p-1">
          <div class="box12">
            <img src="./../../assets/img/clients/img-05.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title"> Sarangapani Swamy Temple</h3>
                <ul class="icon">
                    <li><a href="http://sarangapanitemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
                </ul>
              </div>
          </div> 
        </div>

  <!---------------------------------------Villagers Construction------------------------------------>
        <div class="col-md-3 col-sm-6 col-xs-12 p-1">
          <div class="box12">
            <img src="./../../assets/img/clients/img-06.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title"> Villagers Construction</h3>
                <ul class="icon">
                    <li><a href="http://villagersconstruction.com/" target="_blank"><i class="fa fa-link"></i></a></li>
                </ul>
              </div>
          </div> 
        </div>

  <!-------------------------------------Samayapuram Maariamman------------------------------------->
        <div class="col-md-3 col-sm-6 col-xs-12 p-1">
          <div class="box12">
            <img src="./../../assets/img/clients/img-33.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title">Samayapuram Maariamman</h3>
                <ul class="icon">
                    <li><a href="http://samayapurammariammantemple.in/" target="_blank"><i class="fa fa-link"></i></a></li>
                </ul>
              </div>
          </div> 
        </div>

  <!------------------------------------Seshapureeswarar Temple--------------------------------------->
        <div class="col-md-3 col-sm-6 col-xs-12 p-1">
          <div class="box12">
            <img src="./../../assets/img/clients/img-08.jpg"  class="image" style="width:100%; height:100%;">
            <div class="box-content">
                <h3 class="title"> Seshapureeswarar Temple</h3>
                  <ul class="icon">
                    <li><a href="http://raghukethutemplethiruppampuram.org/" target="_blank"><i class="fa fa-link"></i></a></li>
                  </ul>
            </div>
          </div> 
        </div> 

    </div>

    <div class="row p-4  animatable bounceIn">

  <!------------------------------------Vallalar Temple---------------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-34.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Vallalar Temple</h3>
            <ul class="icon">
                <li><a href="http://vallalardeivanilayam.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div>
    </div>

  <!------------------------------------ Kumbakonam Home Stay---------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-11.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Kumbakonam Home Stay</h3>
            <ul class="icon">
                <li><a href="http://kumbakonamhomestay.com/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
        </div>
      </div> 
    </div> 
  <!----------------------------------Anjaneyar Swamy Temple-------------0---------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-12.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title">Anjaneyar Swamy Temple</h3>
            <ul class="icon">
                <li><a href="http://namakkalnarasimhaswamianjaneyartemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div> 

  <!--------------------------------------Subramaniya Swamy-Ettukudi------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-27.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Subramaniya Swamy-Ettukudi</h3>
            <ul class="icon">
                <li><a href="http://ettukudimurugan.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div> 

  </div>

  <div class="row p-4  animatable bounceInLeft">
    
  <!---------------------------------Swetharanyeswarar Temple--------------------------------------->

    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-13.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title">Swetharanyeswarar Temple</h3>
            <ul class="icon">
                <li><a href="http://thiruvengadubudhan.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div>

  <!----------------------------------Amirtha Kadeswarar Temple-------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-16.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Amirtha Kadeswarar Temple</h3>
            <ul class="icon">
                <li><a href="http://thirukkadaiyurabiramitemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div>

  <!-------------------------------------Thillai Kali Amman Temple-------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-14.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Thillai Kali Amman Temple</h3>
            <ul class="icon">
                <li><a href="http://thillaikaliammantemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div>

  <!------------------------------------Aadhi Kumbeswarar Temple----------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-15.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title">Aadhi Kumbeswarar Temple</h3>
            <ul class="icon">
                <li><a href="http://adhikumbeswarar.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div>  

  </div>

  <div class="row p-4  animatable bounceIn">

  <!-------------------------------------Naganatha Swamy Temple--------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-17.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
            <h3 class="title"> Naganatha Swamy Temple</h3>
            <ul class="icon">
                <li><a href="http://kethutemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div>

  <!---------------------------------------- Manimadam ThiruKovil----------------------------------->
  <div class="col-md-3 col-sm-12 col-12 p-1">
    <div class="box12">
      <img src="./../../assets/img/clients/img-01.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title">Manimadam ThiruKovil</h3>
          <ul class="icon">
              <li><a href="http://manimadam.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
      </div>
    </div>
    <h3></h3>
  </div>

<!------------------------------------------Gurukrupa Consultancy------------------------------------>      
  <div class="col-md-3 col-sm-6 col-xs-12 p-1">
    <div class="box12">
      <img src="./../../assets/img/clients/img-02.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title">Gurukrupa Consultancy</h3>
          <ul class="icon">
              <li><a href="http://gurukrupaconsultancy.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div> 
  </div>

  <!--------------------------------Virudhagireeshwarar Temple----------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-20.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title">Virudhagireeshwarar Temple</h3>
            <ul class="icon">
                <li><a href="http://virudhagireeswartemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div> 

  </div>

  <div class="row p-4  animatable bounceInRight">
  
  <!-------------------------------------Idhaya College--------------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-32.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title">Idhaya College</h3>
            <ul class="icon">
                <li><a href="http://idhayacollegekum.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div>
    </div>

  <!---------------------------------- Theenupureeswarar Temple---------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-31.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Theenupureeswarar Temple</h3>
            <ul class="icon">
                <li><a href="http://patteeswaramdurga.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div>
    </div> 

  <!---------------------------------------- Neelayathatchiamman Temple---------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-24.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Neelayathatchiamman Temple</h3>
            <ul class="icon">
                <li><a href="http://neelayathatchiammantemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div>
    </div>  

  <!------------------------------------------Kumaran Temple---------------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-25.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title">Kumaran Temple</h3>
            <ul class="icon">
                <li><a href="http://kumarantemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>
<!--------------------------------------- MOBILE VIEW ------------------------------------------------->

<div class=" d-block d-sm-none">
  <!------------------------------------------ HEADER IMAGE --------------------------------------->
 <!------------------------------------------TITLE----------------------------------------------->
 <div class="title-box uk-border-rounded p-1 d-none d-sm-block">
  <div class="bg-dark p-1 inner-title-box">
    <h1 class="title-text text-center font-weight-bold">
      clients
    </h1>
    <div class="text-right">
      <h5 class="text-light">
        <span class="sub-menu" routerLink="/home-page">Home </span>&nbsp;/&nbsp;
        <!-- <span class="sub-menu sub-menu-text font-weight-bold">Custom Software Development</span>&nbsp;/&nbsp; -->
        <span class="sub-menu" routerLink="/testimonials"> Testimonials</span>
      </h5>
    </div>
  </div>
 </div>
<!--------------------------------------------------TITLE END-------------------------------------------->
<!------------------------------------------ END ------------------------------------------------->

<!------------------------------------------ WEBSITES---------------------------------------------->
 <div class="bg-light">
  <!-- <div class="container" style="font-size: 40px; font-family: 'Times New Roman', Times, serif;">
    <div class="button-default button-slanted">
      <span class="button-slanted-content"></span>
    </div>
  </div>  -->
  <div class="container-fluid">
    <h4 class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none">
      clients
    </h4>

  <div class="row p-4">
      <div class="col-12">
        <h1 class="text-center text-uppercase d-none d-sm-block">our Websites</h1>
        <h4 class="text-center text-uppercase d-block d-sm-none">our Websites</h4>
      </div>
  <!-------------------------------------Dhandayuthapani Temple-------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-19.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Dhandayuthapani Temple</h3>
            <ul class="icon">
                <li><a href="http://palanimurugantemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
        </div>
      </div> 
    </div> 

    <!------------------------------------Subramanya Swami-Thiruttani----------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-18.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Subramanya Swami-Thiruttani</h3>
            <ul class="icon">
                <li><a href="http://tiruttanigaimurugan.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div>

    <!-----------------------------------------Gowmariyamman Temple---------------------------------------->
        <div class="col-md-3 col-sm-6 col-xs-12 p-1">
          <div class="box12">
            <img src="./../../assets/img/clients/img-03.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title">Gowmariyamman Temple</h3>
                <ul class="icon">
                    <li><a href="http://gowmariyamman.org/" target="_blank"><i class="fa fa-link"></i></a></li>
                </ul>
              </div>
          </div> 
        </div>

    <!------------------------------------------Sri Vanchinathar Temple --------------------------------->
        <div class="col-md-3 col-sm-6 col-xs-12 p-1">
          <div class="box12">
            <img src="./../../assets/img/clients/img-04.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title"> Sri Vanchinathar Temple</h3>
                <ul class="icon">
                    <li><a href="http://srivaanjiyam.org/" target="_blank"><i class="fa fa-link"></i></a></li>
                </ul>
              </div>
          </div> 
        </div><br>
      </div>

    <div class="row p-4">
    
  <!--------------------------------------- Sarangapani Swamy Temple-------------------------------->
        <div class="col-md-3 col-sm-6 col-xs-12 p-1">
          <div class="box12">
            <img src="./../../assets/img/clients/img-05.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title"> Sarangapani Swamy Temple</h3>
                <ul class="icon">
                    <li><a href="http://sarangapanitemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
                </ul>
              </div>
          </div> 
        </div>

  <!---------------------------------------Villagers Construction------------------------------------>
        <div class="col-md-3 col-sm-6 col-xs-12 p-1">
          <div class="box12">
            <img src="./../../assets/img/clients/img-06.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title"> Villagers Construction</h3>
                <ul class="icon">
                    <li><a href="http://villagersconstruction.com/" target="_blank"><i class="fa fa-link"></i></a></li>
                </ul>
              </div>
          </div> 
        </div>

  <!-------------------------------------Samayapuram Maariamman------------------------------------->
        <div class="col-md-3 col-sm-6 col-xs-12 p-1">
          <div class="box12">
            <img src="./../../assets/img/clients/img-33.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title">Samayapuram Maariamman</h3>
                <ul class="icon">
                    <li><a href="http://samayapurammariammantemple.in/" target="_blank"><i class="fa fa-link"></i></a></li>
                </ul>
              </div>
          </div> 
        </div>

  <!------------------------------------Seshapureeswarar Temple--------------------------------------->
        <div class="col-md-3 col-sm-6 col-xs-12 p-1">
          <div class="box12">
            <img src="./../../assets/img/clients/img-08.jpg"  class="image" style="width:100%; height:100%;">
            <div class="box-content">
                <h3 class="title"> Seshapureeswarar Temple</h3>
                  <ul class="icon">
                    <li><a href="http://raghukethutemplethiruppampuram.org/" target="_blank"><i class="fa fa-link"></i></a></li>
                  </ul>
            </div>
          </div> 
        </div> 

    </div>

    <div class="row p-4">

  <!------------------------------------Vallalar Temple---------------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-34.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Vallalar Temple</h3>
            <ul class="icon">
                <li><a href="http://vallalardeivanilayam.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div>
    </div>

  <!------------------------------------ Kumbakonam Home Stay---------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-11.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Kumbakonam Home Stay</h3>
            <ul class="icon">
                <li><a href="http://kumbakonamhomestay.com/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
        </div>
      </div> 
    </div> 
  <!----------------------------------Anjaneyar Swamy Temple-------------0---------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-12.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title">Anjaneyar Swamy Temple</h3>
            <ul class="icon">
                <li><a href="http://namakkalnarasimhaswamianjaneyartemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div> 

  <!--------------------------------------Subramaniya Swamy-Ettukudi------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-27.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Subramaniya Swamy-Ettukudi</h3>
            <ul class="icon">
                <li><a href="http://ettukudimurugan.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div> 

  </div>

  <div class="row p-4">
    
  <!---------------------------------Swetharanyeswarar Temple--------------------------------------->

    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-13.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title">Swetharanyeswarar Temple</h3>
            <ul class="icon">
                <li><a href="http://thiruvengadubudhan.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div>

  <!----------------------------------Amirtha Kadeswarar Temple-------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-16.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Amirtha Kadeswarar Temple</h3>
            <ul class="icon">
                <li><a href="http://thirukkadaiyurabiramitemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div>

  <!-------------------------------------Thillai Kali Amman Temple-------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-14.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Thillai Kali Amman Temple</h3>
            <ul class="icon">
                <li><a href="http://thillaikaliammantemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div>

  <!------------------------------------Aadhi Kumbeswarar Temple----------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-15.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title">Aadhi Kumbeswarar Temple</h3>
            <ul class="icon">
                <li><a href="http://adhikumbeswarar.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div>  

  </div>

  <div class="row p-4">

  <!-------------------------------------Naganatha Swamy Temple--------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-17.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
            <h3 class="title"> Naganatha Swamy Temple</h3>
            <ul class="icon">
                <li><a href="http://kethutemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div>

  <!---------------------------------------- Manimadam ThiruKovil----------------------------------->
  <div class="col-md-3 col-sm-12 col-12 p-1">
    <div class="box12">
      <img src="./../../assets/img/clients/img-01.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title">Manimadam ThiruKovil</h3>
          <ul class="icon">
              <li><a href="http://manimadam.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
      </div>
    </div>
    <h3></h3>
  </div>

<!------------------------------------------Gurukrupa Consultancy------------------------------------>      
  <div class="col-md-3 col-sm-6 col-xs-12 p-1">
    <div class="box12">
      <img src="./../../assets/img/clients/img-02.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title">Gurukrupa Consultancy</h3>
          <ul class="icon">
              <li><a href="http://gurukrupaconsultancy.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div> 
  </div>

  <!--------------------------------Virudhagireeshwarar Temple----------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-20.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title">Virudhagireeshwarar Temple</h3>
            <ul class="icon">
                <li><a href="http://virudhagireeswartemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div> 
    </div> 

  </div>

  <div class="row p-4">
  
  <!-------------------------------------Idhaya College--------------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-32.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title">Idhaya College</h3>
            <ul class="icon">
                <li><a href="http://idhayacollegekum.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div>
    </div>

  <!---------------------------------- Theenupureeswarar Temple---------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-31.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Theenupureeswarar Temple</h3>
            <ul class="icon">
                <li><a href="http://patteeswaramdurga.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div>
    </div> 

  <!---------------------------------------- Neelayathatchiamman Temple---------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-24.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Neelayathatchiamman Temple</h3>
            <ul class="icon">
                <li><a href="http://neelayathatchiammantemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div>
    </div>  

  <!------------------------------------------Kumaran Temple---------------------------------------------->
    <div class="col-md-3 col-sm-6 col-xs-12 p-1">
      <div class="box12">
        <img src="./../../assets/img/clients/img-25.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title">Kumaran Temple</h3>
            <ul class="icon">
                <li><a href="http://kumarantemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>
