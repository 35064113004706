import { Component } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'annaasilicontechnologywebsite';

  constructor() {}

  ngOnit(){}

  onActivate() {
    window.scroll(0,0); 
  }

}
