<div class=" d-none d-sm-block">
    <!------------------------------------------TITLE----------------------------------------------->
    <div class="title-box uk-border-rounded p-1 d-none d-sm-block">
        <div class="bg-dark p-1 inner-title-box">
            <h1 class="title-text text-center font-weight-bold">
                Testimonials
            </h1>
            <div class="text-right">
                <h5 class="text-light">
                    <span class="sub-menu" routerLink="/home-page">Home </span>&nbsp;/&nbsp;
                    <!-- <span class="sub-menu sub-menu-text font-weight-bold">Custom Software Development</span>&nbsp;/&nbsp; -->
                    <span class="sub-menu" routerLink="/upload-cv"> Career</span>
                </h5>
            </div>
        </div>
    </div>
    <!--------------------------------------------------TITLE END-------------------------------------------->
    <div class="bg-light">
        <div class="container pt-3 pb-4">
            <h4 class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none">
                Testimonials
            </h4>
            <div class="container  animatable fadeInUp">
                <div class="row justify-content-center">
                    <div class="col-md-12 col-sm-12 col-12">
                        <div id="demo" class="carousel slide" data-ride="carousel">
                            <!-- Indicators -->
                            <!-- <ul class="carousel-indicators">
                            <li data-target="#demo" data-slide-to="0" class="active"></li>
                            <li data-target="#demo" data-slide-to="1"></li>
                            <li data-target="#demo" data-slide-to="2"></li>
                            <li data-target="#demo" data-slide-to="3"></li>
                            <li data-target="#demo" data-slide-to="4"></li>
                            <li data-target="#demo" data-slide-to="5"></li>
                            </ul> -->

                            <!-- The slideshow -->
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div class="row justify-content-center">
                                        <div class="col-md-8 col-sm-12 col-12 text-center">
                                            <img src="./../../assets/img/testimonials/test-retail.jpg"
                                                class="rounded-circle img-fluid">
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="app-desc text-dark text-center">
                                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                                                Annaa Silicon Technology always under commit and over deliver either be
                                                software, web products.
                                                <i class="fa fa-quote-right" aria-hidden="true"></i>
                                            </div>
                                            <h4 class="font-weight-bold text-dark text-center">
                                                KA Retail,Kumbakonam
                                            </h4>
                                            <!-- <h6 class="font-weight-bold text-dark text-center">
                                               
                                            </h6> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div class="row justify-content-center">
                                        <div class="col-md-8 col-sm-12 col-12 text-center">
                                            <img src="./../../assets/img/testimonials/test-school.jpg"
                                                class="rounded-circle img-fluid">
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="app-desc text-dark text-center">
                                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                                                Annaa Silicon Technology always under commit and over deliver either be
                                                software, web products.
                                                <i class="fa fa-quote-right" aria-hidden="true"></i>
                                            </div>
                                            <h4 class="font-weight-bold text-dark text-center">
                                                Banathurai School, Kumbakonam.
                                            </h4>
                                            <!-- <h6 class="font-weight-bold text-dark text-center">
                                               
                                            </h6> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div class="row justify-content-center">
                                        <div class="col-md-8 col-sm-12 col-12 text-center">
                                            <img src="./../../assets/img/testimonials/test-retail.jpg"
                                                class="rounded-circle img-fluid">
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="app-desc text-dark text-center">
                                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                                                I really appreciate their support to convert our accounts as
                                                computerized. Their service is reliable and secured.
                                                <i class="fa fa-quote-right" aria-hidden="true"></i>
                                            </div>
                                            <h4 class="font-weight-bold text-dark text-center">
                                                SNG Sons, Mayiladuthurai.
                                            </h4>
                                            <!-- <h6 class="font-weight-bold text-dark text-center">
                                                
                                            </h6> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div class="row justify-content-center">
                                        <div class="col-md-8 col-sm-12 col-12 text-center">
                                            <img src="./../../assets/img/testimonials/test-hospital.jpg"
                                                class="rounded-circle img-fluid">
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="app-desc text-dark text-center">
                                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                                                I really appreciate their support to convert our accounts as
                                                computerized. Their service is reliable and secured.
                                                <i class="fa fa-quote-right" aria-hidden="true"></i>
                                            </div>
                                            <h4 class="font-weight-bold text-dark text-center">
                                                Kumar Hospital, Sirkazhi.
                                            </h4>
                                            <!-- <h6 class="font-weight-bold text-dark text-center">
                                                
                                            </h6> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div class="row justify-content-center">
                                        <div class="col-md-8 col-sm-12 col-12 text-center">
                                            <img src="./../../assets/img/testimonials/test-school.jpg"
                                                class="rounded-circle img-fluid">
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="app-desc text-dark text-center">
                                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                                                Annaa Silicon Technology always under commit and over deliver either be
                                                software, web products.
                                                <i class="fa fa-quote-right" aria-hidden="true"></i>
                                            </div>
                                            <h4 class="font-weight-bold text-dark text-center">
                                                Gandhi Matric School, Madhukur.
                                            </h4>
                                            <!-- <h6 class="font-weight-bold text-dark text-center">
                                                
                                            </h6> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div class="row justify-content-center">
                                        <div class="col-md-8 col-sm-12 col-12 text-center">
                                            <img src="./../../assets/img/testimonials/test-pharma.jpg"
                                                class="rounded-circle img-fluid">
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="app-desc text-dark text-center">
                                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                                                I really appreciate their support to convert our accounts as
                                                computerized. Their service is reliable and secured.
                                                <i class="fa fa-quote-right" aria-hidden="true"></i>
                                            </div>
                                            <h4 class="font-weight-bold text-dark text-center">
                                                Ellora Pharma, Madurai.
                                            </h4>
                                            <!-- <h6 class="font-weight-bold text-dark text-center">
                                                Ellora Pharma, Madurai.
                                            </h6> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Left and right controls -->
                            <a class="carousel-control-prev" href="#demo" data-slide="prev">
                                <span class="carousel-control-prev-icon"></span>
                            </a>
                            <a class="carousel-control-next" href="#demo" data-slide="next">
                                <span class="carousel-control-next-icon"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!----------------------------------------------TESTIMONIAL FIGURE--------------------------------------->

    <!-- Section: Testimonials v.2 -->

    <!-- Section: Testimonials v.2 -->
</div>



<!--------------------------------------- MOBILE VIEW ------------------------------------------------->

<div class=" d-block d-sm-none">
    <!------------------------------------------TITLE----------------------------------------------->
    <div class="title-box uk-border-rounded p-1 d-none d-sm-block">
        <div class="bg-dark p-1 inner-title-box">
            <h1 class="title-text text-center font-weight-bold">
                Testimonials
            </h1>
            <div class="text-right">
                <h5 class="text-light">
                    <span class="sub-menu" routerLink="/home-page">Home </span>&nbsp;/&nbsp;
                    <!-- <span class="sub-menu sub-menu-text font-weight-bold">Custom Software Development</span>&nbsp;/&nbsp; -->
                    <span class="sub-menu" routerLink="/upload-cv"> carrer</span>
                </h5>
            </div>
        </div>
    </div>
    <!--------------------------------------------------TITLE END-------------------------------------------->
    <div class="bg-light">
        <div class="container pt-3 pb-4">
            <h4 class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none">
                Testimonials
            </h4>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 col-sm-12 col-12">
                        <div id="demo1" class="carousel slide" data-ride="carousel1">
                            <!-- Indicators -->
                            <!-- <ul class="carousel-indicators">
                        <li data-target="#demo" data-slide-to="0" class="active"></li>
                        <li data-target="#demo" data-slide-to="1"></li>
                        <li data-target="#demo" data-slide-to="2"></li>
                        <li data-target="#demo" data-slide-to="3"></li>
                        <li data-target="#demo" data-slide-to="4"></li>
                        <li data-target="#demo" data-slide-to="5"></li>
                        </ul> -->

                            <!-- The slideshow -->
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div class="row justify-content-center">
                                        <div class="col-md-8 col-sm-12 col-12 text-center">
                                            <img src="./../../assets/img/testimonials/test-retail.jpg"
                                                class="rounded-circle img-fluid">
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="app-desc text-dark text-center">
                                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                                                Annaa Silicon Technology always under commit and over deliver either be
                                                software, web products.
                                                <i class="fa fa-quote-right" aria-hidden="true"></i>
                                            </div>
                                            <h4 class="font-weight-bold text-dark text-center">
                                                KA Retail,Kumbakonam
                                            </h4>
                                            <!-- <h6 class="font-weight-bold text-dark text-center">
                                           
                                        </h6> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div class="row justify-content-center">
                                        <div class="col-md-8 col-sm-12 col-12 text-center">
                                            <img src="./../../assets/img/testimonials/test-school.jpg"
                                                class="rounded-circle img-fluid">
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="app-desc text-dark text-center">
                                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                                                Annaa Silicon Technology always under commit and over deliver either be
                                                software, web products.
                                                <i class="fa fa-quote-right" aria-hidden="true"></i>
                                            </div>
                                            <h4 class="font-weight-bold text-dark text-center">
                                                Banathurai School, Kumbakonam.
                                            </h4>
                                            <!-- <h6 class="font-weight-bold text-dark text-center">
                                           
                                        </h6> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div class="row justify-content-center">
                                        <div class="col-md-8 col-sm-12 col-12 text-center">
                                            <img src="./../../assets/img/testimonials/test-retail.jpg"
                                                class="rounded-circle img-fluid">
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="app-desc text-dark text-center">
                                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                                                I really appreciate their support to convert our accounts as
                                                computerized. Their service is reliable and secured.
                                                <i class="fa fa-quote-right" aria-hidden="true"></i>
                                            </div>
                                            <h4 class="font-weight-bold text-dark text-center">
                                                SNG Sons, Mayiladuthurai.
                                            </h4>
                                            <!-- <h6 class="font-weight-bold text-dark text-center">
                                            
                                        </h6> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div class="row justify-content-center">
                                        <div class="col-md-8 col-sm-12 col-12 text-center">
                                            <img src="./../../assets/img/testimonials/test-hospital.jpg"
                                                class="rounded-circle img-fluid">
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="app-desc text-dark text-center">
                                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                                                I really appreciate their support to convert our accounts as
                                                computerized. Their service is reliable and secured.
                                                <i class="fa fa-quote-right" aria-hidden="true"></i>
                                            </div>
                                            <h4 class="font-weight-bold text-dark text-center">
                                                Kumar Hospital, Sirkazhi.
                                            </h4>
                                            <!-- <h6 class="font-weight-bold text-dark text-center">
                                            
                                        </h6> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div class="row justify-content-center">
                                        <div class="col-md-8 col-sm-12 col-12 text-center">
                                            <img src="./../../assets/img/testimonials/test-school.jpg"
                                                class="rounded-circle img-fluid">
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="app-desc text-dark text-center">
                                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                                                Annaa Silicon Technology always under commit and over deliver either be
                                                software, web products.
                                                <i class="fa fa-quote-right" aria-hidden="true"></i>
                                            </div>
                                            <h4 class="font-weight-bold text-dark text-center">
                                                Gandhi Matric School, Madhukur.
                                            </h4>
                                            <!-- <h6 class="font-weight-bold text-dark text-center">
                                            
                                        </h6> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div class="row justify-content-center">
                                        <div class="col-md-8 col-sm-12 col-12 text-center">
                                            <img src="./../../assets/img/testimonials/test-pharma.jpg"
                                                class="rounded-circle img-fluid">
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="app-desc text-dark text-center">
                                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                                                I really appreciate their support to convert our accounts as
                                                computerized. Their service is reliable and secured.
                                                <i class="fa fa-quote-right" aria-hidden="true"></i>
                                            </div>
                                            <h4 class="font-weight-bold text-dark text-center">
                                                Ellora Pharma, Madurai.
                                            </h4>
                                            <!-- <h6 class="font-weight-bold text-dark text-center">
                                            Ellora Pharma, Madurai.
                                        </h6> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Left and right controls -->
                            <a class="carousel-control-prev text-dark" href="#demo1" data-slide="prev">
                                <span class="carousel-control-prev-icon"></span>
                            </a>
                            <a class="carousel-control-next text-dark" href="#demo1" data-slide="next">
                                <span class="carousel-control-next-icon"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!----------------------------------------------TESTIMONIAL FIGURE--------------------------------------->

    <!-- Section: Testimonials v.2 -->

    <!-- Section: Testimonials v.2 -->
</div>