 
<!--------------------------------ELLORA PHARMACY-------------------------------------------------------->
    <div *ngIf="elloraFlag==true" class="container mt-4 mb-4">
      <div class="container row">
        <h2 class="text-center text-dark mb-4 font-weight-bolder text-uppercase p-2">
          Ellora Pharmacy
        </h2>
      </div>
      <div class="container">
      <div class="row">
        <div id="slider-container" class="slider">
         
<!--------------------------------------------MASTER--------------------->
        <div class="slide"> 
          <div class="col-md-3 col-sm-12 col-xs-12">
              <div class="card card_style">
                 <h4 class="text-center">MASTER</h4>
                      <div class="card-body ">
                          <ul><li>Doctor Master</li>
                              <li>Employee Master</li>
                              <li>Location Master</li>				
                              <li>Area Master</li>
                              <li>Gift Master</li>
                              <li>Sample Master</li>
                          </ul>
                      </div> 
              </div>
          </div>
        </div>
<!---------------------------------------------END------------------------->

<!---------------------------------------------ENTRY------------------------->
            <div class="slide"> 
              <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="card card_style">
                     <h4 class="text-center">ENTRY</h4>
                        <div class="card-body ">
                          <ul><li>Gift Entry & Sample Entry</li>
                              <li>Stockiest Entry</li>
                          </ul>
                        </div>
                </div>
              </div>
            </div>
<!-------------------------------------------------END------------------------>

<!----------------------------------------------ACTIVITY------------------------->
            <div class="slide"> 
              <div class="col-md-3 col-sm-12 col-xs-12">
                  <div class="card card_style">
                     <h4 class="text-center">ACTIVITY</h4>
                          <div class="card-body ">
                              <ul><li>Field</li>
                                <li>Conference / Meeting / Camp</li>
                                <li>Leave</li>				
                                <li>Others</li>
                              </ul>
                          </div>
                  </div>
              </div>
            </div>
<!---------------------------------------------------END------------------------->

<!-------------------------------REPORT------------------------->
            <div class="slide">              
              <div class="col-md-3 col-sm-12 col-xs-12">
                  <div class="card card_style">
                     <h4 class="text-center">REPORT</h4>
                          <div class="card-body ">
                            <ul>
                              <li>Date Wise Report</li>
                              <li>Work Wise Report</li>
                              <li>ASM/RSM Report</li>
                              <li>Sample Wise report</li>
                              <li>Gift Wise Report</li>
                              <li>Leave Report</li>
                              <li>Stock Report</li>
                            </ul>
                          </div>
                  </div>
              </div>  
            </div>
            
            <div (click)="prev()" class="control-prev-btn mt-4">
              <i class="fas fa-arrow-left"></i>
           </div>
           <div (click)="next()" class="control-next-btn mt-4">
             <i class="fas fa-arrow-right"></i>
           </div>
         </div>
         <!-- <div class="overlay"></div> -->
                  

          </div><br><br>
          
            <div class="" style="text-align:center">
                <button type="button" class="btn  btn-dark" (click)="moveBack()">GO BACK</button>
            </div>
      
    </div>
  </div>
<!-----------------------------------------------END------------------------------------------------------->

 <!-- ------------------------------------------SCHOOL MANAGEMENT SYSTEM--------------------------------------------------------------->
  <div *ngIf="schoolFlag==true" class="container mt-4 mb-4">
      <div class="container row">
        <h2 class="text-center text-dark mb-4 font-weight-bolder text-uppercase p-2">
          School Management System
        </h2>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div id="slider-container" class="slider">

<!------------------------MASTER------------------------------>
        <div class="slide"> 
          <div class="col-md-4 col-sm-12 col-xs-12">
              <div class="card card_style1">
                <h4 class="text-center">MASTER</h4>
                      <div class="card-body ">
                          <ul><li>Student Master</li>			
                              <li>Staff Master</li>
                              <li>Section Master</li>			
                              <li>Department Master</li>
                              <li>Class</li>
                          </ul>	
                      </div>
              </div>
          </div> 
        </div>
<!-----------------------------END----------------------------->    
              
<!-----------------------------SMS----------------------------->
              <div class="slide">
                <div class="col-md-4 col-sm-12 col-xs-12">
                  <div class="card card_style1">
                    <h4 class="text-center">SMS</h4>
                        <div class="card-body ">
                          <ul><li>SMS Creation</li>	
                              <li>SMS Master</li>
                              <li>SMS Template</li>
                              <li>SMS History</li>
                          </ul>
                          </div>
                  </div>
                </div>
              </div>
<!-------------------------END------------------------------>

<!-------------------------ACCOUNTS-------------------------->
            
              <div class="slide">
                <div class="col-md-4 col-sm-12 col-xs-12">
                  <div class="card card_style1">
                    <h4 class="text-center">ACCOUNTS</h4>
                          <div class="card-body ">
                              <ul><li>Billing</li>	
                                  <li>Stock Maintenance </li>
                                  <li>Purchase</li>
                                  <li>Receipt</li>
                                  <li>Ledger Statement</li>
                              </ul>
                          </div>
                  </div>
                </div>
              </div>
<!----------------------------END-------------------------->              

<!----------------------------ACCOUNTS REPORT---------------------------->
            <div class="slide">
              <div class="col-md-4 col-sm-12 col-xs-12">
                  <div class="card card_style1">
                    <h4 class="text-center">ACCOUNTS REPORT</h4>
                          <div class="card-body ">
                              <ul><li>Billing Report (Student)</li>	
                                  <li>Receipt Head Wise Report</li>	
                                  <li>Cash Book Report</li>
                              </ul>
                          </div>
                  </div>
              </div>
            </div>
<!------------------------------END--------------------------------------->

<!------------------------------REPORT LIST----------------------------------->
            <div class="slide">
              <div class="col-md-4 col-sm-12 col-xs-12">
                  <div class="card card_style1">
                    <h4 class="text-center">REPORT LIST</h4>
                          <div class="card-body ">
                              <ul><li>Student Admission List</li>	
                                  <li>Caste Wise Student</li>	
                                  <li>Gender Wise Report</li>	
                                  <li>Staff Report </li>
                              </ul>
                          </div>  
                  </div>
              </div>  
            </div>        
<!------------------------------END------------------------------>

<!-------------------CERTIFICATE GENERATION----------------------->
            <div class="slide">     
              <div class="col-md-4 col-sm-12 col-xs-12">
                  <div class="card card_style1">
                    <h4 class="text-center">CERTIFICATE GENERATION</h4>
                          <div class="card-body ">
                              <ul><li>Transfer Certificate</li>	
                                  <li>Conduct Certificate</li>	
                                  <li>Bonafide Certificate</li>
                              </ul>
                          </div>  
                  </div>  
              </div>  
            </div>        
<!-----------------------------END---------------------------->
          
<!-----------------------ATTENDENCE MODULE----------------------->
            <div class="slide"> 
              <div class="col-md-4 col-sm-12 col-xs-12">
                  <div class="card card_style1">
                    <h4 class="text-center">ATTENDENCE MODULE</h4>
                          <div class="card-body ">
                              <ul><li>Attendance Entry</li>	
                                  <li>Student Wise Attendance Report</li>
                              </ul>
                          </div>  
                  </div>
              </div>
            </div>
<!-----------------------------END----------------------------->

<!--------------------------ADMIN------------------------------>
            <div class="slide"> 
              <div class="col-md-4 col-sm-12 col-xs-12">
                  <div class="card card_style1">
                    <h4 class="text-center">ADMIN</h4>
                      <div class="card-body ">
                          <ul><li>User Creation</li>
                          <li>User Rights Control(Role)</li>
                          <li>User Master</li>
                          <li>Profile</li>
                          <li>Change Password</li>
                          </ul>
                      </div>
                  </div>
              </div>
            </div>
<!----------------------------END---------------------------->

          
<!----------------EXAM MANAGEMENT-in Future---------------------->
            <div class="slide"> 
              <div class="col-md-4 col-sm-12 col-xs-12">
                  <div class="card card_style1">
                    <h4 class="text-center">EXAM MANAGEMENT-in Future</h4>
                      <div class="card-body">
                          <ul><li>Exam Schedule</li>
                              <li>Exam Result Management</li>
                          </ul> 
                      </div>
                  </div>
              </div>
            </div>
<!-----------------------------END------------------------------>
      <div (click)="prev()" class="control-prev-btn mt-4">
        <i class="fas fa-arrow-left"></i>
      </div>
      <div (click)="next()" class="control-next-btn mt-4">
      <i class="fas fa-arrow-right"></i>
      </div>
      </div>
      <!-- <div class="overlay"></div> --> 
            

     
    </div><br><br>
          
    <div class="" style="text-align:center">
        <button type="button" class="btn  btn-dark" (click)="moveBack()">GO BACK</button>
    </div>
</div>
</div>
<!------------------------------------------------END--------------------------------------------------->
   
 
<!----------------------------------------------LODGE MANAGEMENT SYSTEM----------------------------------------------->
    <div *ngIf="lodgeFlag==true" class="container mt-4 mb-4">
      <div class="container row">
        <h2 class="text-center text-dark mb-4 font-weight-bolder text-uppercase p-2">
         Lodge Management System
        </h2>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div id="slider-container" class="slider">
              <!--------------------------------------------MASTER--------------------------------------->
                <div class="slide"> 
                  <div class="col-md-3 col-sm-12 col-xs-12">
                    <div class="card card_style2">
                      <h4 class="text-center">MASTER</h4>
                        <div class="card-body ">
                          <ul><li>Cottage Master</li>			
                              <li>Room Type and Room Master</li>
                              <li>Add New Online Booking</li>
                              <li>Import Online Booking</li>	
                          </ul>		
                        </div>
                    </div>
                  </div>
                </div>
                  <!--------------------------------------- END ------------------------------------------>
  
                  <!--------------------------------------BOOKING----------------------------------------->
                <div class="slide"> 
                  <div class="col-md-3 col-sm-12 col-xs-12">
                    <div class="card card_style2">
                      <h4 class="text-center">BOOKING</h4>
                        <div class="card-body ">
                          <ul><li>Room Check-in</li>	
                              <li>Room Check Out</li>
                              <li>Room Transform</li>
                          </ul>
                        </div>
                    </div>
                  </div>
                </div>
                  <!---------------------------------------------END ------------------------------------>
  
                  <!----------------------------------HISTORY-------------------------------------------->
              <div class="slide"> 
                <div class="col-md-3 col-sm-12 col-xs-12">
                  <div class="card card_style2">
                    <h4 class="text-center">HISTORY</h4>
                      <div class="card-body ">
                        <ul><li>Booking History </li>	
                            <li>Transfer History </li>
                            <li>Online Room Booking History</li>
                        </ul>
                      </div>
                  </div>
                </div>
              </div>
                <!---------------------------------------------- END ------------------------------------>
               
                <!-----------------------------------------REPORT---------------------------------------->
              <div class="slide"> 
                <div class="col-md-3 col-sm-12 col-xs-12">
                  <div class="card card_style2">
                    <h4 class="text-center">REPORT</h4>
                      <div class="card-body ">
                          <ul><li>Cottage Report</li>	
                              <li>Room Wise Report</li>	
                              <li>Lodge Report</li>	
                              <li>Ledger Statement Report</li>
                              <li>Cash Book Report</li>
                              <li>Deposit Report</li>
                              <li>Abstract Report</li>
                          </ul>
                      </div>
                  </div>
                </div>
              </div>
            <!-------------------------------------END-------------------------------------->
        
             <!---------------------------------------ACCOUNTS REPORT-------------------------------->
            <div class="slide"> 
             <div class="col-md-3 col-sm-12 col-xs-12">
              <div class="card card_style2">
                <h4 class="text-center">ACCOUNTS REPORT</h4>
                  <div class="card-body ">
                    <ul><li>Billing Report(Student)</li>	
                        <li>Receipt Head Wise Report</li>	
                        <li>Cash Book Report</li>
                    </ul>
                  </div>
              </div>
            </div>
          </div>
            <!------------------------------------END--------------------------------------->
  
            <!---------------------------------------CHITTA--------------------------------->
            <div class="slide"> 
              <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="card card_style2">
                  <h4 class="text-center">CHITTA</h4>
                    <div class="card-body ">
                      <ul><li>Prepare Chitta</li>	
                          <li>Get Chitta Report</li>	
                      </ul>
                    </div>
                </div>
              </div>
            </div>
          <!--------------------------------------END--------------------------------------->
  
          <!---------------------------------------BANK TRANSACTION--------------------------------->
         <div class="slide"> 
           <div class="col-md-3 col-sm-12 col-xs-12">
            <div class="card card_style2">
              <h4 class="text-center">BANK TRANSACTION</h4>
                <div class="card-body ">
                  <ul><li>Transaction Entry</li>	
                      <li>Maintaining Entry List</li>	
                  </ul>
                </div>
            </div>
          </div>
        </div>
          <!------------------------------------END--------------------------------------->
            
          <!--------------------------------------SETTING-------------------------------->
        <div class="slide"> 
          <div class="col-md-3 col-sm-12 col-xs-12">
            <div class="card card_style2">
              <h4 class="text-center">SETTING</h4>
                <div class="card-body ">
                  <ul><li>Lodge Setting</li>	
                  </ul>
                </div>
            </div>
          </div>
        </div>
          <!------------------------------------END--------------------------------------->
          <div (click)="prev()" class="control-prev-btn mt-4">
            <i class="fas fa-arrow-left"></i>
          </div>
          <div (click)="next()" class="control-next-btn mt-4">
          <i class="fas fa-arrow-right"></i>
          </div>
        </div>
      </div><br><br>

      <div class="" style="text-align:center">
          <button type="button" class="btn btn-dark" (click)="moveBack()">GO BACK</button>
      </div>
</div>
</div>
<!------------------------------------------------END---------------------------------------------------------------->


<!----------------------------------------------INVENTORY SOFTWARE -------------------------------------------->
    <div *ngIf="inventoryFlag==true" class="container mt-4 mb-4">
      <div class="container row">
        <h2 class="text-center text-dark mb-4 font-weight-bolder text-uppercase p-2">
        Inventory Software
        </h2>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div id="slider-container" class="slider">
          <!--------------------------------------------MASTER--------------------------------------->
        <div class="slide"> 
          <div class="col-md-3 col-sm-12 col-xs-12">
              <div class="card card_style1">
                <h4 class="text-center">MASTER</h4>
                  <div class="card-body ">
                    <ul><li>Product Group </li>			
                        <li>Unit Master</li>
                        <li>Product Master</li>
                        <li>Plan Master</li>
                    </ul>		
                  </div>
              </div>
            </div> 
          </div>
<!--------------------------------------- END ------------------------------------------>

<!------------------------------------------ENTRY-------------------------------------->
          <div class="slide"> 
            <div class="col-md-3 col-sm-12 col-xs-12">
              <div class="card card_style1">
                <h4 class="text-center">ENTRY</h4>
                  <div class="card-body ">
                    <ul><li>Stock Entry </li>			
                        <li>Plan Bulk Entry</li>
                        <li>Product Entry</li>
                    </ul>		
                  </div>
              </div>
            </div> 
          </div>
<!--------------------------------------- END ------------------------------------------>

<!--------------------------------ENTRY LIST--------------------------------------->
          <div class="slide"> 
            <div class="col-md-3 col-sm-12 col-xs-12">
              <div class="card card_style1">
                <h4 class="text-center">ENTRY LIST</h4>
                  <div class="card-body ">
                    <ul><li>Stock Entry List</li>			
                        <li>Plan Bulk Entry List</li>
                        <li>Product Entry List</li>
                    </ul>		
                  </div>
              </div>
            </div> 
          </div>
<!--------------------------------------- END ------------------------------------------>
        
<!-------------------------------REPORT--------------------------------------->      
        <div class="slide">                   
          <div class="col-md-3 col-sm-12 col-xs-12">
            <div class="card card_style1">
              <h4 class="text-center">REPORT</h4>
                <div class="card-body ">
                  <ul><li>Plan wise Details Report with PDF</li>			
                      <li>Stock Report</li>
                      <li>Stock Abstract Report</li>
                      <li>Plan Detail Report</li>
                      <li>Stock Detail Report</li>
                  </ul>		
                </div>
            </div>
          </div> 
        </div>
<!--------------------------------------- END ------------------------------------------>
      
<!-------------------------------ADMIN------------------------------------->
        <div class="slide">        
          <div class="col-md-3 col-sm-12 col-xs-12">
            <div class="card card_style1">
              <h4 class="text-center">ADMIN</h4>
                <div class="card-body ">
                  <ul><li>User Creation</li>			
                      <li>User Rights Control (Role)</li>
                      <li>User Master</li>
                      <li>Profile</li>
                      <li>Change Password</li>
                      <li>Back up</li>
                  </ul>		
                </div>
            </div>
          </div> 
        </div>
      <!------------------------------END-------------------------------->    
      <div (click)="prev()" class="control-prev-btn mt-4">
        <i class="fas fa-arrow-left"></i>
      </div>
      <div (click)="next()" class="control-next-btn mt-4">
      <i class="fas fa-arrow-right"></i>
      </div>
    </div>
      </div><br><br>

      <div class="" style="text-align:center">
          <button type="button" class="btn btn-dark" (click)="moveBack()">GO BACK</button>
      </div>
</div>
</div>
<!---------------------------------------------END----------------------------------------------------------->


<!----------------------------------------------ANNAA ANTIBIOTIC-------------------------------------------->
    <div *ngIf="antibioticFlag==true" class="container mt-4 mb-4">
      <div class="container row">
        <h2 class="text-center text-dark mb-4 font-weight-bolder text-uppercase p-2">
          Annaa Antibiotic
        </h2>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div id="slider-container" class="slider">
          <!--------------------------------------------REGISTRATION--------------------------------------->
        <div class="slide">     
          <div class="col-md-3 col-sm-12 col-xs-12">
              <div class="card card_style3">
                <h4 class="text-center">REGISTER</h4>
                  <div class="card-body ">
                    <ul><li>Patient Registration(with photo upload)</li>			
                    </ul>		
                  </div>
              </div>
            </div>
          </div>
 <!--------------------------------------- END ------------------------------------------>
      
 <!--------------------------------------------APPOINTMENT-------------------------------->
    <div class="slide">   
      <div class="col-md-3 col-sm-12 col-xs-12">
        <div class="card card_style3">
          <h4 class="text-center">APPOINTMENT</h4>
            <div class="card-body ">
              <ul><li>Doctor Appointment</li>			
              </ul>		
            </div>
        </div>
      </div>
    </div>
<!--------------------------------------- END ------------------------------------------>

<!----------------------------------VITAL INFORMATION-------------------------------->
  <div class="slide">   
    <div class="col-md-3 col-sm-12 col-xs-12">
      <div class="card card_style3">
        <h4 class="text-center">VITAL INFORMATION</h4>
          <div class="card-body ">
            <ul><li>View Vital Information</li>			
            </ul>		
          </div>
      </div>
    </div>
  </div>
<!--------------------------------------- END ------------------------------------------>
  
<!-------------------------------------------PRESCRIPTION-------------------------------->
  <div class="slide">   
    <div class="col-md-3 col-sm-12 col-xs-12">
      <div class="card card_style3">
        <h4 class="text-center">PRESCRIPTION</h4>
          <div class="card-body ">
            <ul><li>Get Old prescription </li>	
                <li>Visit history Details</li>	
                <li>View waiting Patient List</li>	
            </ul>		
          </div>
      </div>
    </div>
  </div>
<!--------------------------------------- END ------------------------------------------>
 
<!-------------------------------------------MASTER-------------------------------->
  <div class="slide">   
    <div class="col-md-3 col-sm-12 col-xs-12">
      <div class="card card_style3">
        <h4 class="text-center">MASTER</h4>
          <div class="card-body">
            <ul><li>Patient Master</li>		
                <li>Doctor Master</li>
                <li>Test master</li>
                <li>Staff and Billing Master</li>
                <li>Drug Type and Drug Master</li>		
                <li>Complaints</li>		
            </ul>		
          </div>
      </div>
    </div>
  </div>
<!--------------------------------------- END ------------------------------------------>

<!-----------------------------------------BILLING------------------------------->
    <div class="slide">   
      <div class="col-md-3 col-sm-12 col-xs-12">
        <div class="card card_style3">
          <h4 class="text-center">BILLING</h4>
            <div class="card-body ">
              <ul><li>Patient In</li>		
                  <li>Patient Out</li>	
              </ul>		
            </div>
        </div>
      </div>
    </div>
<!--------------------------------------- END ------------------------------------------>

<!----------------------------------------ADMIN------------------------------->
    <div class="slide">   
      <div class="col-md-3 col-sm-12 col-xs-12">
        <div class="card card_style3">
          <h4 class="text-center">ADMIN</h4>
            <div class="card-body ">
              <ul><li>User Creation</li>		
                  <li>User Rights Control (Role)</li>	
                  <li>User Master</li>
                  <li>Profile</li>
                  <li>Change Password</li>
                  <li>Back up</li>
              </ul>		
            </div>
        </div>
      </div>
    </div>
<!--------------------------------------- END ------------------------------------------>

<!----------------------------------------DISCHARGE------------------------------->
    <div class="slide">   
      <div class="col-md-3 col-sm-12 col-xs-12">
        <div class="card card_style3">
          <h4 class="text-center">DISCHARGE</h4>
            <div class="card-body ">
              <ul><li>Create discharge </li>		
                  <li>Maintain discharge </li>	
              </ul>		
            </div>
        </div>
      </div>
    </div>
<!----------------------------------------------END------------------------------------------------->
      <div (click)="prev()" class="control-prev-btn mt-4">
        <i class="fas fa-arrow-left"></i>
      </div>
      <div (click)="next()" class="control-next-btn mt-4">
      <i class="fas fa-arrow-right"></i>
      </div>
      </div>
      </div><br><br>

      <div class="" style="text-align:center">
          <button type="button" class="btn btn-dark" (click)="moveBack()">GO BACK</button>
      </div>
</div>
</div>

<!---------------------------------------------END----------------------------------------------------------->


<!----------------------------------------------TICKET BOOKING SOFTWARE -------------------------------------------->
    <div *ngIf="ticketFlag==true" class="container mt-4 mb-4">
      <div class="container row">
        <h2 class="text-center text-dark mb-4 font-weight-bolder text-uppercase p-2">
          Ticket Booking Software
        </h2>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div id="slider-container" class="slider">

          <!---------------------------------MASTER---------------------------------->
        <div class="slide">
          <div class="col-md-3 col-sm-12 col-xs-12">
              <div class="card card_style2">
                <h4 class="text-center">MASTER</h4>
                  <div class="card-body ">
                    <ul><li>Devotee Master</li>			
                        <li>Booking Master</li>
                        <li>Ticket Master</li>
                        <li>Location Master</li>	
                    </ul>		
                  </div>
              </div>
            </div> 
          </div>
<!--------------------------------------- END ------------------------------------------>

 <!--------------------------------------------BOOKING-------------------------------------->
          <div class="slide">
            <div class="col-md-3 col-sm-12 col-xs-12">
              <div class="card card_style2">
                <h4 class="text-center">BOOKING</h4>
                  <div class="card-body ">
                    <ul><li>Get Devotee details</li>			
                        <li>Amount Report</li>
                        <li>Booking Receipt </li>
                    </ul>		
                  </div>
              </div>
            </div> 
          </div>
<!--------------------------------------- END ------------------------------------------>

<!---------------------------------VERIFICATION--------------------------------------->
          <div class="slide">
            <div class="col-md-3 col-sm-12 col-xs-12">
              <div class="card card_style2">
                <h4 class="text-center">VERIFICATION</h4>
                  <div class="card-body ">
                    <ul><li>Verify Tickets </li>			
                        <li>Verify based on QR scanner</li>
                        <li>Confirm User Details</li>
                    </ul>		
                  </div>
              </div>
            </div> 
          </div>
<!--------------------------------------- END ------------------------------------------>
      
<!-------------------------------BOOKING REPORT--------------------------------------->
        <div class="slide">
          <div class="col-md-3 col-sm-12 col-xs-12">
            <div class="card card_style2">
              <h4 class="text-center">BOOKING REPORT</h4>
                <div class="card-body ">
                  <ul><li>Day wise Booking Report</li>			
                      <li>Status Wise Report</li>
                      <li>Devotees Abstract Report</li>
                  </ul>		
                </div>
            </div>
          </div> 
        </div>
<!--------------------------------------- END ------------------------------------------>
    
<!--------------------------------LOCATION REPORT--------------------------------------->
        <div class="slide">
          <div class="col-md-3 col-sm-12 col-xs-12">
            <div class="card card_style2">
              <h4 class="text-center">LOCATION REPORT</h4>
                <div class="card-body ">
                  <ul><li>Sales Details Report</li>			
                      <li>Abstract Report</li>
                      <li>Over all Abstract Report</li>
                      <li>Consolidated Location Report</li>
                      <li>Uploading Signature</li>
                  </ul>		
                </div>
            </div>
          </div> 
        </div>
<!--------------------------------------- END ------------------------------------------>

<!---------------------------------ADMIN--------------------------------------->
        <div class="slide">
          <div class="col-md-3 col-sm-12 col-xs-12">
            <div class="card card_style2">
              <h4 class="text-center">ADMIN</h4>
                <div class="card-body ">
                  <ul><li>User Creation</li>			
                      <li>User Rights Control (Role)</li>
                      <li>User Master</li>
                      <li>Profile</li>
                      <li>Change Password</li>
                  </ul>		
                </div>
            </div>
          </div> 
        </div>
<!----------------------------------------END---------------------------------------------->
        <div (click)="prev()" class="control-prev-btn mt-4">
          <i class="fas fa-arrow-left"></i>
        </div>
        <div (click)="next()" class="control-next-btn mt-4">
        <i class="fas fa-arrow-right"></i>
        </div>
        </div>
        </div><br><br>

      <div class="" style="text-align:center">
          <button type="button" class="btn btn-dark" (click)="moveBack()">GO BACK</button>
      </div>
  </div>
</div>

<!---------------------------------------------END----------------------------------------------------------->

<!---------------------------------------------ENGINEERING SOFTWARE SYSTEM -------------------------------------------->
    <div *ngIf="engineeringFlag==true" class="container mt-4 mb-4">
      <div class="container row">
        <h2 class="text-center text-dark mb-4 font-weight-bolder text-uppercase p-2">
         Engineering Software System
        </h2>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div id="slider-container" class="slider">
<!--------------------------------------------MASTER--------------------------------------->
        <div class="slide">
          <div class="col-md-3 col-sm-12 col-xs-12">
              <div class="card card_style3">
                <h4 class="text-center">MASTER</h4>
                  <div class="card-body ">
                  <ul><li>Receipt Type Master</li>			
                      <li>Tender Bidder Master</li>
                      <li>Work Master</li>
                  </ul>		
                  </div>
              </div>
          </div> 
        </div>
<!--------------------------------------- END ------------------------------------------>

<!------------------------------------------ENTRY-------------------------------------->
        <div class="slide">
          <div class="col-md-3 col-sm-12 col-xs-12">
              <div class="card card_style3">
                <h4 class="text-center">ENTRY</h4>
                  <div class="card-body ">
                  <ul><li>Estimation</li>			
                      <li>Quotation</li>
                      <li>Tender Bidder</li>
                      <li>Receipt</li>
                      <li>Tender Action</li>
                      <li>Tender Bidder Document</li>
                  </ul>		
                  </div>
              </div>
          </div> 
        </div>
<!--------------------------------------- END ------------------------------------------>

<!--------------------------------ENTRY LIST--------------------------------------->
        <div class="slide">
          <div class="col-md-3 col-sm-12 col-xs-12">
              <div class="card card_style3">
                <h4 class="text-center">ENTRY LIST</h4>
                  <div class="card-body ">
                  <ul><li>Maintain Estimation</li>			
                      <li>Maintain Quotation</li>
                      <li>Tender List</li>
                      <li>Receipt Entry List</li>
                      <li>Tender Action List</li>
                      <li>Tender Bidder Document</li>
                  </ul>		
                  </div>
              </div>
          </div> 
        </div>
<!--------------------------------------- END ------------------------------------------>

<!-------------------------------WORK-------------------------------------->
      <div class="slide">
        <div class="col-md-3 col-sm-12 col-xs-12">
          <div class="card card_style3">
            <h4 class="text-center">WORK</h4>
              <div class="card-body ">
                  <ul><li>Add Work Order</li>			
                      <li>Agreement</li>
                      <li>Maintain work Progress</li>
                      <li>Payment Details for Agreed Projects</li>
                      <li>Maintain Completion Status</li>
                  </ul>		
              </div>
          </div>
          </div>
        </div> 
<!--------------------------------------- END ------------------------------------------>
 
<!-------------------------------ACTION PROCESS--------------------------------------->
    <div class="slide">
      <div class="col-md-3 col-sm-12 col-xs-12">
      <div class="card card_style3">
        <h4 class="text-center">ACTION PROCESS</h4>
          <div class="card-body ">
              <ul><li>Tender Action</li>			
                  <li>Quotation Action</li>
              </ul>		
          </div>
      </div>
      </div> 
    </div>
    
<!--------------------------------------- END ------------------------------------------>

<!--------------------------------REPORT------------------------------------->
    <div class="slide">
      <div class="col-md-3 col-sm-12 col-xs-12">
      <div class="card card_style3">
        <h4 class="text-center">REPORT</h4>
          <div class="card-body ">
              <ul><li>Tender Report</li>			
                  <li>Quotation Report</li>
                  <li>Progress Details</li>
                  <li>Payment Details</li>
                  <li>Bidder Report</li>
              </ul>		
          </div>
      </div>
      </div>
    </div> 
<!--------------------------------------- END ------------------------------------------>

<!-------------------------------ADMIN------------------------------------->
    <div class="slide">
      <div class="col-md-3 col-sm-12 col-xs-12">
      <div class="card card_style3">
        <h4 class="text-center">ADMIN</h4>
          <div class="card-body ">
              <ul><li>User Creation</li>			
                  <li>User Rights Control (Role)</li>
                  <li>User Master</li>
                  <li>Profile</li>
                  <li>Change Password</li>
                  <li>Back up</li>
              </ul>		
          </div>
      </div>
      </div> 
    </div>
<!--------------------------------------END-------------------------------------------------->  
      <div (click)="prev()" class="control-prev-btn mt-4">
        <i class="fas fa-arrow-left"></i>
      </div>
      <div (click)="next()" class="control-next-btn mt-4">
      <i class="fas fa-arrow-right"></i>
      </div>
      </div>
      </div><br><br>

      <div class="" style="text-align:center">
          <button type="button" class="btn btn-dark" (click)="moveBack()">GO BACK</button>
      </div>
  </div>
</div>

<!---------------------------------------------END----------------------------------------------------------->


<!----------------------------------------------STUDENT STIPEND SOFTWARE------------------------------------------->
<div *ngIf="stipendFlag==true" class="container mt-4 mb-4">
  <div class="container row">
    <h2 class="text-center text-dark mb-4 font-weight-bolder text-uppercase p-2">
      Student Stipend Software
    </h2>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div id="slider-container" class="slider">
<!--------------------------------------------MASTER--------------------------------------->
        <div class="slide">
          <div class="col-md-3 col-sm-12 col-xs-12">
              <div class="card card_style3">
                <h4 class="text-center">MASTER</h4>
                  <div class="card-body ">
                  <ul><li>Department</li>			
                      <li>Course Type and Course Master</li>
                      <li>Student Detail</li>
                      <li>Maintaining Holiday List</li>
                  </ul>		
                  </div>
              </div>
          </div> 
        </div>
<!--------------------------------------- END ------------------------------------------>

<!--------------------------------------ATTENDANCE-------------------------------------->
        <div class="slide">
          <div class="col-md-3 col-sm-12 col-xs-12">
              <div class="card card_style3">
                <h4 class="text-center">ATTENDANCE</h4>
                  <div class="card-body ">
                  <ul><li>Day wise Attendance Entry</li>			
                      <li>Maintaining Reason</li>
                  </ul>		
                  </div>
              </div>
          </div> 
        </div>
<!--------------------------------------- END ------------------------------------->

<!-------------------------------REPORT--------------------------------------->
        <div class="slide">
          <div class="col-md-3 col-sm-12 col-xs-12">
              <div class="card card_style3">
                <h4 class="text-center">REPORT</h4>
                  <div class="card-body ">
                  <ul><li>Get Report for Attendance details</li>			
                      <li>Get Single Selected Month Attendance Report</li>
                      <li>Get Over all Stipend Amount for Single Month</li>
                  </ul>		
                  </div>
              </div>
          </div> 
        </div>
<!--------------------------------------- END ------------------------------------------>

<!----------------------------ACCOUNTS REPORT-------------------------------------->
      <div class="slide">
        <div class="col-md-3 col-sm-12 col-xs-12">
          <div class="card card_style3">
            <h4 class="text-center">ACCOUNTS REPORT</h4>
          
            <div class="card-body ">
              <ul><li>Billing Report(Student)</li>			
                  <li>Receipt Head Wise Report</li>
                  <li>Cash Book Report</li>
              </ul>		
            </div>
          </div>
        </div> 
      </div>
<!--------------------------------------- END ------------------------------------------>

<!--------------------------------------------REPORT LIST-------------------------------------->
          <div class="slide">
            <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="card card_style3">
                  <h4 class="text-center">REPORT LIST</h4>
                    <div class="card-body ">
                        <ul><li>Student Admission List</li>			
                            <li>Caste Wise Student</li>
                            <li>Gender Wise Report</li>
                            <li>Staff Report </li>
                        </ul>		
                    </div>
                </div>
            </div> 
          </div>
  <!--------------------------------------- END ------------------------------------------>
  
  <!-------------------------------------SETTINGS--------------------------------------->
          <div class="slide">
            <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="card card_style3">
                  <h4 class="text-center">SETTINGS</h4>
                    <div class="card-body ">
                        <ul><li>Attendance Setting</li>			
                            <li>Stipend Setting</li>
                        </ul>		
                    </div>
                </div>
            </div> 
          </div>
  <!--------------------------------------- END ------------------------------------->
  
  <!-------------------------------ADMIN--------------------------------------->
          <div class="slide">
            <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="card card_style3">
                  <h4 class="text-center">ADMIN</h4>
                  <div class="card-body ">
                      <ul><li>User Creation</li>			
                          <li>User Rights Control (Role)</li>
                          <li>User Master</li>
                          <li>Profile</li>
                          <li>Change Password</li>
                          <li>Back up</li>
                      </ul>		
                  </div>
                </div> 
            </div> 
          </div>
  <!--------------------------------------- END ------------------------------------------>
        <div (click)="prev()" class="control-prev-btn mt-4">
          <i class="fas fa-arrow-left"></i>
        </div>
        <div (click)="next()" class="control-next-btn mt-4">
        <i class="fas fa-arrow-right"></i>
        </div>
        </div>
        </div><br><br>

      <div class="" style="text-align:center">
          <button type="button" class="btn btn-dark" (click)="moveBack()">GO BACK</button>
      </div>
    </div>
  </div>

<!--------------------------------------------END----------------------------------------------------------->
